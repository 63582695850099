import React, { useState, useContext, } from "react"
import { RootContext } from "../../RootContext"

// core components
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import MaterialTable, { MTableEditField } from "@material-table/core"
import { fetchWithToken } from "../../components/Fetch/api-fetch"
import { titleCase } from "title-case"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"
import { MenuItem, Select } from "@material-ui/core"

//Pegaso component
import CustomPagination from "components/CustomMaterialTable/CustomPagination"
import ErrorUtils from "components/Tools/ErrorUtils"



function CustomSelect(props) {
  const {t} = useContext(RootContext)
  const { rowId, rowData } = props
  const [selectedVal, setSelectedVal] = useState(rowData.active !== undefined ? rowData.active : true)
  
  function handleChange(e) {
    const val = e.target.value
    rowData.active = val
    setSelectedVal(val)
  }

  return (
    <Select
      value={selectedVal}
      onChange={handleChange}
      name={"supplierId_" + rowId}
    >
      <MenuItem key={true} value={true} selected={selectedVal === true}>{t("10147")}</MenuItem>
      <MenuItem key={false} value={false} selected={selectedVal === true}>{t("10079")}</MenuItem>
    </Select>
  )
}

export default function FrameSuppliers(props) {
  const { companyId, t, apiUrl, access_token, refresh_token, setAccess_token, } = useContext(RootContext)
  const { tableLine, isFetchingFrameSuppliers, setIsFetching, setEndLoadingFrameSuppliers } = props
  const classes = useJobsStyles()
  const [duplicateError, setDuplicateError] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [open, setOpen] = useState(false)

  //Colonne della material table
  let initColumns = [
    {
      title: t("10384").toUpperCase(),
      field: "frameSupplierId",
      editable: "never",
      headerStyle: { width: 25, maxWidth: 25 },
      cellStyle: { width: 25, maxWidth: 25 },
    },
    {
      title: t("10378").toUpperCase(),
      field: "frameSupplierName",
      editComponent: (props) => (
        <MTableEditField
          {...props}
          error={props.value === "" || !props.value}
          onChange={(e) => {
            if (props.onChange) props.onChange(e)
          }}
        />
      ),
    },
    {
      title: t("10134").toUpperCase(),
      field: "formattedActive",
      editComponent: (props) => (
        <CustomSelect {...props} value={props.value} rowId={props.rowData.frameSupplierId} />
      ),
    },
  ]

  //Put o post del frame supplier
  const handlePutOrPost = (newSupplier, method, resolve, reject) => {
    const url = method === "POST" ? `${apiUrl}/companies/${companyId}/frameSupplier` : `${apiUrl}/companies/${companyId}/frameSupplier/${newSupplier.frameSupplierId}`
    fetchWithToken(
      url,
      {
        method: method,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          frameSupplierName: newSupplier.frameSupplierName,
          active: newSupplier.active !== undefined ? newSupplier.active : true 
        }),
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      }
    )
      .then((response) => response.body)
      .then((data) => {
        resolve()
        setIsFetching(true)
        setIsSaving(false)
      })
      .catch((err) => {
        reject()
        const { body } = err
        if (body) {
          const { code } = body
          if (code === "E3") {
            setDuplicateError(true)
          }
        }
        setIsSaving(false)
        setIsFetching(false)
        setOpen(true)
        ErrorUtils.errorLog("opticianPutError", err)
      })
  }

  //delete del frame supplier
  function handleDelete(frameSupplierId, resolve, reject) {
    setIsSaving(true)
    setEndLoadingFrameSuppliers(false)
    const url = `${apiUrl}/companies/${companyId}/frameSupplier/${frameSupplierId}`
    fetchWithToken(
      url,
      {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token,
      }
    )
      .then((response) => response.body)
      .then((data) => {
        resolve()
        setIsFetching(true)
        setIsSaving(false)
      })
      .catch((err) => {
        setIsSaving(false)
        setOpen(true)
        ErrorUtils.errorLog("opticianPutError", err)
      })
  }

  //chiusura del pannello per errore
  const handleClose = () => {
    setOpen(false)
    setDuplicateError(false)
  }

  return (
    <div>
      <MaterialTable
        isLoading={isSaving || isFetchingFrameSuppliers}
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        title={t("10327")}
        columns={initColumns}
        data={tableLine}
        components={{
          Pagination: (props) => {
            const { rowsPerPage, rowsPerPageOptions } = props
            return <CustomPagination
              {...props}
              count={tableLine.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          }
        }}
        options={{
          actionsColumnIndex: 0,
          pageSize: 5,
          pageSizeOptions: [5, 10, 15],
          draggable: false,
          showFirstLastPageButtons: false,
        }}
        localization={{
          toolbar: {
            searchTooltip: titleCase(t("10328")),
            searchPlaceholder: titleCase(t("10328")),
          },
          header: { actions: "" },
          body: {
            emptyDataSourceMessage: t("10156"),
            addTooltip: t("10157"),
            deleteTooltip: t("10158"),
            editTooltip: t("10159"),
            editRow: {
              deleteText: t("10162"),
              cancelTooltip: t("10010"),
              saveTooltip: t("10161"),
            },
          },
        }}
        editable={{
          isEditable: (rowData) =>
            true,
          isDeletable: (rowData) =>
            true,
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              handlePutOrPost(newData, "POST", resolve, reject)
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              handlePutOrPost(newData, "PUT", resolve, reject)
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              handleDelete(oldData.frameSupplierId, resolve, reject)
            }),
        }}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("28")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {duplicateError ? t("10329") : t("27")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {" "}
        Ok{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}