const it = {
  translation: {
    1: "errore: impossibile stampare ordine",
    2: "errore: impossibile salvare ordine",
    3: "il diametro deve essere maggiore di 1",
    4: "il diametro deve essere numerico",
    5: "nessuna forma disponibile",
    6: "il valore dello sfero deve essere numerico",
    7: "il valore dello sfero non è corretto",
    8: "valore dello sfero fuori limite (-30 / +30)",
    9: "il valore del cilindro deve essere numerico",
    10: "il valore del cilindro non è corretto",
    11: "valore del cilindro fuori limite (-10 / +10)",
    12: "il valore dell' asse deve essere numerico",
    13: "il valore dell' asse deve essere positivo",
    14: "il valore dell'asse deve essere inferiore a 360",
    15: "il valore dell'asse deve essere un numero intero",
    16: "il valore deve essere numerico",
    17: "il valore deve essere positivo",
    18: "il valore deve essere maggiore di",
    19: "il valore deve essere inferiore a",
    20: "il valore deve essere compreso tra -10 e 10",
    21: "il diametro deve essere minore di 99",
    22: "nessun Tracer locale specificato.",
    23: "PegasoDriver - Dispositivo non raggiungibile (Servizio non attivo o porta già impegnata). Conttatare l`assistenza per supporto.  ",
    24: "TIMEOUT: Nessuna forma ricevuta",
    25: "il valore deve essere un numero intero",
    26: "salvatagggio fallito: ottico con questo codice già esistente",
    27: "errore durante il salvataggio.",
    28: "operazione Fallita",
    29: "prodotto o forma mancanti.",
    30: "forma mancante",
    31: "campi obbligatori mancanti.",
    32: "campi con errori.",
    33: "informazioni sull'azienda non trovate",
    34: "intestazione catalogo non trovata",
    35: "impossibile aprire job",
    36: "impossibile inserire job",
    37: "impossibile clonare job",
    38: "sessione scaduta",
    39: "DA DATA deve essere minore di A DATA",
    40: "il valore non è corretto",
    41: "impossibile aggiornare status del job",
    42: "impossibile inoltrare job a fornitori",
    43: "errore",
    44: "nessun fornitore trovato",
    45: "nessun catalogo caricato",
    46: "codice prodotto mancante",
    47: "catalogo non trovato",
    48: "pegasoTools errore fetch",
    49: "impossibile importare catalogo",
    50: "nessun blocker locale specificato.",
    51: "Login non riuscito",
    5000: "aspherica",
    5001: "torica",
    5002: "bifocale",
    5003: "curve top",
    5004: "doppio segmento",
    5005: "e-line multifocale",
    5006: "flat top",
    5007: "lenticolare",
    5008: "progressive",
    5009: "quadrifocale",
    5010: "round",
    5011: "monofocale",
    5012: "trifocale",
    10000: "addizione",
    10001: "asferica",
    10002: "torica",
    10003: "automatica",
    10004: "asse (T)",
    10005: "bisello",
    10006: "valore di posizione",
    10007: "posizione bisello",
    10008: "bifocale",
    10009: "marca",
    10010: "annulla",
    10011: "chiuso",
    10012: "città",
    10013: "azienda",
    10014: "completa il tuo profilo",
    10015: "nazione",
    10016: "curve top",
    10017: "cilindro",
    10018: "inserimento",
    10019: "aggiornato da",
    10020: "caricato da",
    10021: "aggiornamento",
    10022: "scaricato da",
    10023: "dashboard",
    10024: "giorni",
    10025: "ponte",
    10026: "dispositivi",
    10027: "dispositivo",
    10028: "diametro",
    10029: "disabilitato",
    10030: "doppio segmento",
    10031: "dati montaggio",
    10032: "modifica",
    10033: "modifica profilo",
    10034: "e-line multifocale",
    10035: "tipo montaggio",
    10036: "filtro statistiche ordini",
    10037: "filtro stato",
    10038: "note",
    10039: "flat top",
    10040: "materiale montature",
    10041: "da data",
    10042: "nylor",
    10043: "dima orizzontale",
    10044: "home",
    10045: "id",
    10046: "codice",
    10047: "aumento ordini rispetto alla scorsa settimana",
    10048: "inserito da",
    10049: "distanza interpupillare",
    10050: "ordine",
    10051: "lista ordini",
    10052: "lista Ottici",
    10053: "ordine stampato",
    10054: "ordini del giorno",
    10055: "ordini della settimana",
    10056: "ordini dell'anno",
    10057: "ordine inviato correttamente",
    10058: "ordine selezionato",
    10059: "statistiche ordini",
    10060: "laboratorio",
    10061: "note laboratorio",
    10063: "laboratori",
    10064: "cognome",
    10065: "lente sinistra",
    10066: "riferimento ordine lab. esterno",
    10067: "lenticolare",
    10068: "lista ordini inseriti oggi",
    10069: "lista ordini dell'anno precedente ",
    10070: "tipo lente",
    10071: "login",
    10072: "logout",
    10073: "indirizzo e-mail",
    10074: "metallo",
    10075: "modello",
    10076: "mesi",
    10077: "nome",
    10078: "sono arrivati nuovi ordini ​​in laboratorio",
    10079: "no",
    10080: "ordine non selezionato",
    10081: "nessun ordine da modificare",
    10082: "nessun risultato trovato con questi filtri",
    10083: "nessuna forma disponibile",
    10084: "esporta forma",
    10085: "forma da file",
    10086: "forma da tracer",
    10087: "filtri",
    10088: "altezza montaggio",
    10089: "offset",
    10090: "aperto",
    10091: "apri ordine",
    10092: "ottico",
    10093: "cliente ottico",
    10094: "note ottico",
    10095: "optyl",
    10096: "n° ordine",
    10097: "password",
    10098: "telefono",
    10099: "plastica",
    10100: "si prega di filtrare l'elenco degli ordini che si desidera vedere",
    10101: "selezionare il periodo delle statisctiche che si vuole vedere",
    10102: "lucidatura",
    10103: "stampa",
    10104: "ristampa",
    10105: "profilo",
    10106: "progressive",
    10107: "provincia",
    10108: "quadrifocale",
    10109: "ordini recenti",
    10110: "tipo di risultato",
    10111: "lente destra",
    10112: "glasant",
    10113: "round",
    10114: "salva",
    10115: "seleziona posizione bisello",
    10116: "seleziona lucidatura",
    10117: "cerca anno",
    10118: "cerca ordine",
    10119: "cerca ottico",
    10120: "cerca note ottico",
    10121: "seleziona ordine da modificare",
    10122: "seleziona ordine per vedere i dettagli",
    10123: "impostazioni",
    10124: "seleziona tipo montaggio",
    10125: "seleziona finitura bordo",
    10126: "monofocale",
    10127: "seleziona tipo lente",
    10128: "sfero",
    10129: "stato",
    10130: "statistiche",
    10131: "periodo statistiche",
    10132: "via",
    10133: "civ.",
    10134: "attivo",
    10135: "data di iscrizione",
    10136: "a data",
    10137: "trifocale",
    10138: "tipo",
    10139: "aggiorna profilo",
    10140: "aggiornato il",
    10141: "aggiornamento ordine",
    10142: "username",
    10143: "impostazioni utente",
    10144: "dima verticale",
    10145: "settimane",
    10146: "in lavorazione",
    10147: "si",
    10148: "CAP",
    10149: "server non raggiungibile",
    10150: "credenziali non valide",
    10151: "formato file non valido",
    10152: "aggiungi nuovo ordine",
    10153: "visualizza ordine",
    10154: "modifica ordine",
    10155: "clona ordine",
    10156: "nessun risultato",
    10157: "crea",
    10158: "elimina",
    10159: "modifica",
    10160: "invalido",
    10161: "salva",
    10162: "sicuro di voler eliminare questa riga?",
    10163: "accendere il Tracer.",
    10164: "posizionare la montatura sul Tracer.",
    10165: "premere il tasto verde con il disegno degli occhiali per eseguire la lettura.",
    10166: "terminata la lettura della forma premere nuovamente il tasto verde con il disegno degli occhiali.",
    10167: "premere il tasto sul programma Pc -Ricevi-.",
    10168: "terminata la lettura della forma (quando suona il BEEP), premere il tasto sul programma Pc -Ricevi-.",
    10169: "premere il tasto verde per la lettura.",
    10170: "mentre il Tracer legge la forma, premere il tasto sul programma Pc -Ricevi-.",
    10171: "effettuare la lettura della montatura.",
    10172: "premere il tasto -Salva- dal tracer per inviare la forma al Pc.",
    10173: "premere il tasto -Trans- sul Tracer, una volta che ha terminato la lettura.",
    10174: "premere il tasto -B (BOTH)- per la lettura.",
    10175: "verificare che sullo schermo del Tracer in alto a destra ci sia scritto -RSA- (In caso contrario premere CTL e +/- per cambiarlo in -RSA- e riavviare il tracer).",
    10176: "premi 2 volte -Start- sul tracer per eseguire la lettura.",
    10177: "premere il tasto -DATA- alla fine della lettura quando il led accanto si accende.",
    10178: "premere il tasto Start per la lettura.",
    10179: "se GT5000: Premere il tasto -DATA- alla fine della lettra quando il led accanto si accende.",
    10180: "connetti Singolo",
    10181: "ricevi Singolo",
    10182: "disconnetti",
    10183: "ricevi Ciclico",
    10184: "ricevi",
    10185: "connetto a dispositivo...",
    10186: "attendo dati dal dispositivo...",
    10187: "disconnetto dal dispositivo...",
    10188: "riga selezionata:",
    10189: "righe per pagina:",
    10190: "pagina Precedente",
    10191: "pagina Successiva",
    10192: "listini (L.)",
    10193: "p. iva",
    10194: "numero",
    10195: "indirizzo",
    10196: "catalogo",
    10197: "gestione catalogo",
    10198: "carica",
    10199: "",
    10200: "catalogo aggiornato correttamente",
    10201: "campi filtro",
    10202: "campi dettaglio",
    10203: "campi valore",
    10204: "codici esterni",
    10205: "conferma",
    10206: "sei sicuro di voler eliminare il catalogo corrente?",
    10207: "catalogo caricato",
    10208: "attenzione",
    10209: "trascina il file xlsx del catalogo qua o clicca",
    10210: "cerca prodotto",
    10211: "avanti",
    10212: "percentuale",
    10213: "dietro",
    10214: "modifica",
    10215: "prodotto associato",
    10216: "stai aggiungendo il seguente prodotto:",
    10217: "trascina qua i filtri per raggruppare i prodotti.",
    10218: "aggiorna Lista ordini",
    10219: "campi obbligatori",
    10220: "campi obbligatori aggiornati correttamente",
    10221: "dati forma",
    10222: "dati Ordine",
    10223: "rimuovi Prodotto",
    10224: "ordina prodotti per:",
    10225: "tele-Assistenza",
    10226: "campi obbligatori dell'ordine",
    10227: "selezionare un ordine singolo oppure un periodo di ordini da ricercare",
    10228: "selezionare due periodi di ordini da ricercare dello stesso anno",
    10229: "OK",
    10230: "svuota",
    10231: "vuoto",
    10232: "oggi",
    10233: "resetta filtri",
    10234: "invia job a fornitore",
    10235: "selezionare il fornitore a cui si vuole inoltrare l'ordine",
    10236: "invia",
    10237: "anno",
    10238: "premere per chiudere",
    10239: "job inoltrato a fornitore",
    10240: "elimina ordine",
    10241: "attenzione",
    10242: "sei sicuro di voler eliminare l'ordine corrente?",
    10243: "annulla",
    10244: "conferma",
    10245: "ordine creato da",
    10246: "sistema Tabo",
    10247: "spessore minimo al bordo 2mm",
    10248: "ordine chiuso correttamente",
    10249: "chiudi Ordine",
    10250: "manuale",
    10251: "",
    10252: "seleziona marca",
    10253: "nome dispositivo",
    10254: "seriale",
    10255: "ip",
    10256: "porta di comunicazione",
    10257: "inserire solo il numero della porta",
    10258: "velocità porta",
    10259: "bit di parità",
    10260: "bit di dati",
    10261: "bit di stop",
    10262: "indirizzo ip",
    10263: "porta ip",
    10264: "nessuno",
    10265: "pari",
    10266: "dispari",
    10267: "impostazioni protocollo avanzate",
    10268: "opzioni download",
    10269: "opzioni upload",
    10270: "salva modifiche",
    10271: "impostazioni seriale avanzate",
    10272: "ip protocol",
    10273: "job without barcode",
    10274: "nack",
    10275: "offset metal",
    10276: "offset plastic",
    10277: "single side",
    10278: "tracer id",
    10279: "without barcode",
    10280: "req",
    10281: "trcfmtbin",
    10282: "trcfmtpoints",
    10283: "zfmtbin",
    10284: "zfmtpoints",
    10285: "selezionato",
    10286: "timeout",
    10287: "handshake",
    10288: "readBufferSize",
    10289: "writeBufferSize",
    10290: "readTimeout",
    10291: "writeTimeout",
    10292: "rts",
    10293: "dtr",
    10294: "discardNull",
    10295: "offset altro",
    10296: "impostazioni salvate effettuare il logout per renderle effettive",
    10297: "ordine modificato correttamente",
    10298: "invia job a fornitore come tracer (PORTA SERIALE)",
    10299: "connect deve essere almeno versione 1.5.1",
    10300: "materiale lente",
    10301: "scivolosità lente",
    10302: "non scivoloso",
    10303: "spessore bordo",
    10304: "spessore centro",
    10305: "larghezza canalino",
    10306: "profondità canalino",
    10307: "smusso alto",
    10308: "smusso basso",
    10309: "seleziona materiale lente",
    10310: "seleziona scivolosità lente",
    10311: "poco scivoloso",
    10312: "molto scivoloso",
    10313: "impostazioni tracciatore",
    10314: "impostazioni taratura aggiornate",
    10315: "impostazioni taratura",
    10316: "valore altezza  (Y)",
    10317: "valore larghezza  (X)",
    10318: "per una corretta taratura del disegno sullo schermo occorre modificare i valori X ed Y in modo che entrambi i lati del quadrato abbiano una lunghezza di 80 mm",
    10319: "gestione fori",
    10320: "aggiungi foro",
    10321: "cancella fori",
    10322: "salva fori",
    10323: "scegli numero ordine",
    10324: "numero ordine manuale",
    10325: "massimo",
    10326: "gestione montature",
    10327: "lista fornitori",
    10328: "cerca fornitore",
    10329: "fornitore già esistente",
    10330: "fornitore",
    10331: "dati montatura",
    10332: "marca",
    10333: "id montatura",
    10334: "nome modello",
    10335: "colore",
    10336: "calibro",
    10337: "lista marche",
    10338: "cerca marche",
    10339: "marca già esistente",
    10340: "modifica montatura",
    10341: "cancella montatura",
    10342: "impossibile aprire montatura",
    10343: "salvatagggio fallito: montatura con questo id già esistente",
    10344: "montatura non salvata",
    10345: "forma da database",
    10346: "premere il tasto per avviare la lettura.",
    10347: "modifica forma",
    10348: "salva forma",
    10349: "su",
    10350: "giu",
    10351: "destra",
    10352: "sinistra",
    10353: "massimo 1 mese",
    10354: "tipo modifica fori",
    10355: "seleziona il tipo di modifica dei fori",
    10356: "al centro",
    10357: "al bordo",
    10358: "convertitore autocad",
    10359: "file OMA convertito in DXF correttamente. Premere per scaricare",
    10360: "verifica che il file sia corretto e poi riprova",
    10361: "errore: Riprovare, se il problema persiste contattare Pegaso",
    10362: "convertitore da OMA a DXF",
    10363: "trascina il file OMA della forma o clicca qui",
    10364: "cliccare per convertire il file",
    10365: "converti file",
    10366: "scarica file",
    10367: "convertitore da DXF a OMA",
    10368: "come vuoi il risultato?",
    10369: "entrambi",
    10370: "com'è strutturato il file?",
    10371: "trascina il file DXF della forma o clicca qui",
    10372: "file DXF convertito in OMA correttamente. Premere per scaricare",
    10373: "il progetto AutoCad deve contenere soltanto SPLINE e POLYLINE per la rappresentazione della forma, e CIRCLE per la rappresentazione dei fori",
    10374: "qualsiasi altro elemento all'interno del progetto AutoCad verrà ignorato",
    10375: "i file OMA da convertire devono rispettare tutti gli standard del protocollo OMA/VCA.",
    10376: "montatura",
    10377: "marchio montatura",
    10378: "fornitore montatura",
    10379: "campi visibili dai clienti",
    10380: "vero",
    10381: "falso",
    10382: "aggiungi nuova montatura",
    10383: "id fornitore",
    10384: "id marca",
    10385: "lato",
    10386: "trascina il file forma o clicca qui",
    10387: "cerca cliente ottico",
    10388: "guida al funzionamento del convertiore: ",
    10389: "altezza dal Box",
    10390: "larghezza bisello posteriore",
    10391: "larghezza bisello anteriore",
    10392: "seleziona",
    10393: "CR - plastica",
    10394: "Policarbonato",
    10395: "vetro",
    10396: "pattern",
    10397: "alto indice",
    10398: "trivex",
    10399: "tribird",
    10400: "curva montatura",
    10401: "curva base",
    10402: "angolo avvolgimento montatura",
    10403: "indice di rifrazione",
    10404: "seleziona indice di rifrazione",
    10405: "seleziona il tipo tracer e la porta seriale di comunicazione del tracer per la creazione di job tramite interfaccia web",
    10406: "dati lente",
    10407: "esci",
    10408: "inserisci",
    10409: "altezza dal Bordo",
    10410: "esporta catalogo excel",
    10411: "trattamento",
    10412: "seleziona tipo ricerca job",
    10413: "cerca job singolo",
    10414: "cerca jobs in periodo",
    10415: "ripristina ordine per sistemi esterni",
    10416: "job ripristinato correttamente",
    10417: "job non ripristinato",
    10418: "angolo pantoscopico",
    10419: "distanza apice corneale",
    10420: "impostazioni ordini",
    10421: "metodo inserimento altezza di montaggio",
    10422: "dal box",
    10423: "dal bordo",
    10424: "eliminato",
    10425: "impossibile cancellare forma",
    10426: "sei sicuro di voler eliminare questa forma?",
    10427: "stato",
    10428: "valore posizione Y troppo grande (foro ",
    10429: "valore posizione X troppo grande (foro ",
    10430: "forme precaricate",
    10431: "gestione forme precaricate",
    10432: "aggiorna forme precaricate",
    10433: "aggiungi nuova forma",
    10434: "id forma",
    10435: "descrizione",
    10436: "mantieni proporzioni",
    10437: "lingua",
    10438: "impostazioni salvate correttamente, uscire dal programma",
    10439: "invia job a blocker (PORTA SERIALE)",
    10440: "disconnessione blocker in corso",
    10441: "connessione al blocker in corso",
    10442: "in attesa di inviare i dati",
    10443: "dati inviati correttamente",
    10445: "nessuna forma inviata",
    10446: "prima pagina",
    10447: "ultima pagina",
    10448: "rimuovi tracer e salva",
    10449: "attenzione: stai provando a inviare un ordine già inoltrato. Vuoi continuare?",
    10450: "diametro minimo",
    10451: "diametro selezionato",
    10452: "calcola diametro",
    10453: "per il calcolo sono necessari distanza interpupillare, altezza montaggio e dima orrizontale e verticale",
    10454: "cerca modello",
    10455: "prisma",
    10456: "base prisma",
    10457: "trascina una cartella qui",
    10458: "file all'interno della cartella caricati",
    10459: "cliccare per importare forme",
    10460: "importa forme",
    10461: "file importati",
    10462: "file non importati",
    10463: "importa file XML da Wecolab",
  }
}
export default it
