import React, { useState, useContext, useCallback } from "react"
import { RootContext } from "../../../RootContext"

// core components
import { Accordion, AccordionSummary } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionActions'
import Box from "@material-ui/core/Box"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import CloseIcon from "@material-ui/icons/Close"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import IconButton from "@material-ui/core/IconButton"
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from "@material-ui/core/Typography"
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from "@material-ui/core/useScrollTrigger"
import { titleCase } from "title-case"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"
import { useJobsTableStyles } from "styles/jobs/jobsTableCss"

//Pegaso component
import { fetchWithToken } from "../../../components/Fetch/api-fetch"
import ErrorUtils from "components/Tools/ErrorUtils"
import FrameListToolbar from "views/FramesManagement/FrameListToolbar"
import FrameListTableHead from "views/FramesManagement/FrameListTableHead"
import FrameListTableBody from "views/FramesManagement/FrameListTableBody"

export default function JobFrame(props) {
  const { companyId, apiUrl, access_token, refresh_token, setAccess_token, t } = useContext(RootContext)
  const { handleFrameUpload, setOpenFrameList, onHandleClose, frameBrands, frameSuppliers, } = props
  const classes = useJobsStyles()
  const classesTable = useJobsTableStyles()
  const [count, setCount] = useState(1)
  const [frameSupplierId, setFrameSupplierId] = useState("")
  const [frameBrandId, setFrameBrandId] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [emptyRows, setEmptyRows] = useState(0)
  const [frames, setFrames] = useState([])
  const [isFetchingFrames, setIsFetchingFrames] = useState(false)
  const [frameBrandsToShow, setFrameBrandsToShow] = useState(frameBrands)
  function ElevationScroll(props) {
    const { children, window } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    })

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    })
  }


  /**********************************************
   *  FETCH
   **********************************************/

  ///Fetch dei frame
  const fetchFrame = useCallback((frameSupplierId, frameBrandId) => {
    if (frameSupplierId !== "" && frameBrandId !== "") {
      if (isFetchingFrames === true) {
        fetchWithToken(`${apiUrl}/companies/${companyId}/listFrames/frameSupplier/${frameSupplierId}/frameBrand/${frameBrandId}`, {
          method: "GET",
          headers: {
            Accept: +"application/json",
            "Content-Type": "application/json"
          },
          apiUrl: apiUrl,
          companyId: companyId,
          access_token: access_token,
          refresh_token: refresh_token,
          setAccess_token: setAccess_token
        })
          .then(response => {
            const body = response.body
            const { frames } = body
            setEmptyRows(rowsPerPage - Math.min(rowsPerPage, frames.length - page * rowsPerPage))
            setFrames(frames)
            setIsFetchingFrames(false)
          })
          .catch(err => {
            setIsFetchingFrames(false)
            ErrorUtils.errorLog("fetchFrame", err)
            ErrorUtils.errorLog("Frame fetch error.", err.message)
          })
      }
    }
  }, [access_token, apiUrl, companyId, page, rowsPerPage, refresh_token, setAccess_token, isFetchingFrames])

  //Fetch dei frame brand
  const fetchFrameByModelId = useCallback((modelName) => {
    fetchWithToken(`${apiUrl}/companies/${companyId}/listFrames/modelName/${modelName}`, {
      method: "GET",
      headers: {
        Accept: +"application/json",
        "Content-Type": "application/json"
      },
      apiUrl: apiUrl,
      companyId: companyId,
      access_token: access_token,
      refresh_token: refresh_token,
      setAccess_token: setAccess_token
    })
      .then(response => {
        const body = response.body
        const { frames } = body
        setFrames(frames)
        setIsFetchingFrames(false)
      })
      .catch(err => {
        ErrorUtils.errorLog("Frame Brands fetch Error.", err.message)
        setIsFetchingFrames(false)

      })

  }, [access_token, apiUrl, companyId, refresh_token, setAccess_token])

  /**********************************************
     *  HANDLER
     **********************************************/

  // Click change page
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  // Click rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(1)
  }

  //Handler cambio filtri
  function handleChangeFilter(filterField, targetValue) {
    setIsFetchingFrames(true)
    setFrames([])
    setCount(0)
    if (filterField === "frameSupplier") {
      var newFrameBrand = frameBrands.filter(option =>
        option.supplierId === parseInt(targetValue)
      )
      newFrameBrand.unshift({
        value: 0,
        text: ""
      })
      setFrameBrandsToShow(newFrameBrand)
      setFrameSupplierId(targetValue)
      fetchFrame(targetValue, frameBrandId)
    }
    else if (filterField === "frameBrand") {
      setFrameBrandId(targetValue)
      fetchFrame(frameSupplierId, targetValue)
    }
    else if (filterField === "searchModelName") {
      fetchFrameByModelId(targetValue)
    }
  }
  return (
    <div>
      {/* X CHIUSURA IN ALTO */}
      <ElevationScroll {...props}>
        <Box display="flex" flexDirection="row-reverse" p={1}>
          <Box p={1}>
            <IconButton
              aria-label={t("10238")}
              variant="outlined"
              onClick={onHandleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </ElevationScroll>

      {/*Pannello filtri*/}
      {<Accordion defaultExpanded={false}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{titleCase(t("10087"))}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>

            <CardHeader color="primary">
              <FrameListToolbar
                frameBrandId={frameBrandId}
                frameSupplierId={frameSupplierId}
                handleChangeFilter={(filterField, targetValue) => {
                  handleChangeFilter(filterField, targetValue)
                }}
                selectOptionFrameSuppliers={frameSuppliers}
                selectOptionFrameBrands={frameBrandsToShow}
              />
            </CardHeader>

          </Card>
        </AccordionDetails>
      </Accordion>}
      <Card>
        <CardBody>
          <Toolbar id="back-to-top-anchor" />
          <Table className={classes.table} aria-label="simple table">
            <FrameListTableHead isFullTable={false} />
            <FrameListTableBody
              isFullTable={false}
              rows={frames}
              classes={classesTable}
              emptyRows={emptyRows}
              page={page}
              rowsPerPage={rowsPerPage}
              setOpenFrameList={setOpenFrameList}
              handleFrameUpload={handleFrameUpload}
              loading={isFetchingFrames}
            />
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardBody>
      </Card>
    </div >
  )
}