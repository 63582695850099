import React, { useState } from "react"

// @material-ui/core components
import Cookies from "universal-cookie"
import decode from "jwt-decode"
import MqttHandler from "./MQTTHandler"
import { useTranslation } from "react-i18next"
import { SnackbarProvider } from "notistack"

// pegaso components
import JobUtils from "components/Utils/JobUtils"

export const RootContext = React.createContext()
export const apiUrl = process.env.REACT_APP_API_URL + "/api/v1"
export const publicUrl = process.env.REACT_APP_PUBLIC_URL
export const brokerUrl = process.env.REACT_APP_BROKER_URL
export const brokerPort = process.env.REACT_APP_BROKER_PORT
export const brokerPrefix = process.env.REACT_APP_BROKER_PREFIX.trim()
export const apiUrlPegasoTools = process.env.REACT_APP_API_URL_PEGASO_TOOLS + "/api"
export const apiAutoCadUser = process.env.REACT_APP_API_AUTOCAD_USER
export const apiAutoCadPassword = process.env.REACT_APP_API_AUTOCAD_PASSWORD

export default ({ children }) => {
  // Get vars from sessionStorage and cookies
  const cookies = new Cookies()
  const prevAuth = window.sessionStorage.getItem("auth") || false
  const prevAccess_token = window.sessionStorage.getItem("access_token") || null
  const prevRefresh_token = cookies.get("refresh_token") || null
  const company_id = window.sessionStorage.getItem("company_id") || null
  const company_name =
    window.sessionStorage.getItem("company_name") || "Pegaso Connect"
  const lens = addLensType(JSON.parse(window.sessionStorage.getItem("lens")))
  const preCatalogueScope =
    JSON.parse(window.sessionStorage.getItem("catalogue")) || null
  const preLabScopes =
    JSON.parse(window.sessionStorage.getItem("labScopes")) || null
  const preUserDevice =
    JSON.parse(window.sessionStorage.getItem("userDevice")) || null
  const prevRequiredFields = JSON.parse(window.sessionStorage.getItem("requiredFields")) || []
  const prevAccess_token_Pegaso_Tools = window.sessionStorage.getItem("access_token_pegaso_tools") || null
  const prevRefresh_token_Pegaso_Tools = cookies.get("refresh_token_pegaso_tools") || null

  // States
  const [authenticated, setAuthenticated] = useState(prevAuth)
  const [companyId, setCompanyId] = useState(company_id)
  const [companyName, setCompanyName] = useState(company_name)
  const { i18n, t } = useTranslation() // per lingua
  const [access_token, setAccess_token] = useState(prevAccess_token)
  const [refresh_token, setRefresh_token] = useState(prevRefresh_token)
  const [access_token_pegaso_tools, setAccess_token_pegaso_tools] = useState(prevAccess_token_Pegaso_Tools)
  const [refresh_token_pegaso_tools, setRefresh_token_pegaso_tools] = useState(prevRefresh_token_Pegaso_Tools)
  document.title = companyName

  const [lensTypes, setLensTypes] = useState(lens)
  const [requiredFields, setRequiredFields] = useState(prevRequiredFields)
  const [catalogueScope, setCatalogueScope] = useState(preCatalogueScope)
  const [labScopes, setLabScopes] = useState(preLabScopes)
  const [userDevice, setUserDevice] = useState(preUserDevice)
  //Aggiunge lenstype vuoto
  function addLensType(lens) {
    var lensType = lens
    lens ?
      lensType.unshift({ "id": "", "description": "" }) : lensType = JobUtils.lensTypeValues()
    return lensType
  }

  const mqttClient = new MqttHandler()
  let user_claims_global
  const initializeUserClaims = () => {
    if (!user_claims_global) {
      const { user_claims } = decode(access_token)
      user_claims_global = user_claims
    }
  }
  let identityGlobal
  const initializeIdentity = () => {
    if (!identityGlobal) {
      const { identity } = decode(access_token)
      identityGlobal = identity
    }
  }
  const defaultContext = {
    authenticated,
    access_token,
    refresh_token,
    access_token_pegaso_tools,
    refresh_token_pegaso_tools,
    mqttClient,
    t,
    companyId,
    setCompanyId,
    companyName,
    setCompanyName: companyName => {
      sessionStorage.setItem("company_name", companyName)
      setCompanyName(companyName)
    },
    requiredFields,
    setRequiredFields: requiredFields => {
      sessionStorage.setItem("requiredFields", JSON.stringify(requiredFields))
      setRequiredFields(requiredFields)
    },
    catalogueScope,
    setCatalogueScope: catalogueScope => {
      sessionStorage.setItem("catalogue", JSON.stringify(catalogueScope))
      setCatalogueScope(catalogueScope)
    },
    labScopes,
    setLabScopes: labScopes => {
      sessionStorage.setItem("labScopes", JSON.stringify(labScopes))
      setLabScopes(labScopes)
    },
    userDevice,
    setUserDevice: userDevice => {
      if(userDevice !== undefined){
        sessionStorage.setItem("userDevice", JSON.stringify(userDevice))
        setUserDevice(userDevice)
      }
    },
    getLensTypes: () => {
      const result = lensTypes.map(lens => {
        return { value: lens["id"], label: t(lens["description"]) }
      })

      return result
    },
    setLensTypes: lensTypes => {
      sessionStorage.setItem("lens", JSON.stringify(lensTypes))
      setLensTypes(lensTypes)
    },
    setAccess_token,
    login: (access_token, refresh_token) => {
      sessionStorage.setItem("company_id", companyId)
      sessionStorage.setItem("access_token", access_token, { path: "/" })
      cookies.set("refresh_token", refresh_token)
      setAccess_token(access_token)
      setRefresh_token(refresh_token)
      setAuthenticated(true)
      // Imposta lingua utente
      const { user_claims } = decode(access_token)
      const { language } = user_claims
      i18n.changeLanguage(language)
    },
    logout: () => {   
      setAccess_token(null)
      setRefresh_token(null)
      setAuthenticated(false)
      setCatalogueScope(null)
      setLensTypes(JobUtils.lensTypeValues())
      setRequiredFields(null)
      setLabScopes(null)
      setAccess_token_pegaso_tools(null)
      sessionStorage.removeItem("company_id")
      sessionStorage.removeItem("company_name")
      sessionStorage.removeItem("lens")
      sessionStorage.removeItem("access_token")
      sessionStorage.removeItem("catalogue")
      sessionStorage.removeItem("lens")
      sessionStorage.removeItem("requiredFields")
      sessionStorage.removeItem("labScopes")
      sessionStorage.removeItem("access_token_pegaso_tools")
      cookies.remove("refresh_token")
      cookies.remove("refresh_token_pegaso_tools")
      mqttClient.doDisconnect()
    },
    setAccess_token_pegaso_tools,
    loginPegasoTools: (access_token, refresh_token) => {
      sessionStorage.setItem("access_token_pegaso_tools", access_token)
      cookies.set("refresh_token_pegaso_tools", refresh_token)
      setAccess_token_pegaso_tools(access_token)
      setRefresh_token_pegaso_tools(refresh_token)
    },
    getLab: () => {
      initializeUserClaims()
      const { lab } = user_claims_global
      return lab
    },
    getLabId: access_token => {
      const { user_claims } = decode(access_token)
      const { lab } = user_claims
      return lab.labId
    },
    isLabUser: () => {
      initializeUserClaims()
      const { roles } = user_claims_global
      return roles[0] === 2
    },
    isOpticianUser: () => {
      initializeUserClaims()
      const { roles } = user_claims_global
      return roles[0] === 4
    },
    getOptician: () => {
      initializeUserClaims()
      const { optician } = user_claims_global
      return optician
    },
    getUsername: () => {
      initializeIdentity()
      return identityGlobal
    },
    getUtc: () => {
      initializeUserClaims()
      // Vecchio sistema (tenere codice)
      //var date = new Date();
      //var localUTC = (date.getTimezoneOffset() / 60) * -1;
      //user_claims_global.utc = localUTC
      const { utc } = user_claims_global
      return utc
    },
    getLanguage: () => {
      initializeUserClaims()
      const { language } = user_claims_global
      return language
    },
    getIsDemo: () => {
      initializeUserClaims()
      const { demo } = user_claims_global
      return demo
    },
    getIsDemoToken: access_token => {
      const { user_claims } = decode(access_token)
      const { demo } = user_claims
      return demo
    },
    getHubScopes: () => {
      initializeUserClaims()
      const { hubScopes } = user_claims_global
      return hubScopes
    },
    getCalibration: () => {
      initializeUserClaims()
      const { settings } = user_claims_global
      if (settings === undefined) return { dpiX: 25, dpiY: 25 }
      else if (settings.calibration === undefined) return { dpiX: 25, dpiY: 25 }
      else return settings.calibration
    },
    getDrillManagementActive: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(11)
    },
    getAutocadConverterFromOMAtoDXFActive: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(12)
    },
    getFrameManagementActive: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(13)
    },
    getManualJobActive: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(14)
    },
    getAutoSetupTracer: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(16)
    },
    getJobToBlockerActive: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(22)
    },
    getServicePort: () => {
      initializeUserClaims()
      const { settings } = user_claims_global
      if (settings === undefined) return 3001
      else if (settings.servicePort === undefined) return 3001
      else return settings.servicePort
    },
    getEdgingEightType: () => {
      initializeUserClaims()
      const { settings } = user_claims_global
      if (settings === undefined) return "BOX"
      else if (settings.edgingEightType === undefined) return "BOX"
      else return settings.edgingEightType
    },
    getCurrency: () => {
      initializeUserClaims()
      const { currency } = user_claims_global
      return currency
    },
    getExistsExternalSystem: () => {
      initializeUserClaims()
      const { existExternalSystemScope } = user_claims_global
      return existExternalSystemScope
    },
    getCustomProductPersonalized: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(20)
    },
    getPrecalibrationActive: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(21)
    },
    getDiameterMinimumCalculationActive: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(23)
    },
    getImportXMLFromWecoLab: () => {
      initializeUserClaims()
      const { scopeWithoutSettingsActive } = user_claims_global
      return scopeWithoutSettingsActive.includes(24)
    },
    publicUrl,
    apiAutoCadUser,
    apiAutoCadPassword,
    apiUrl,
    apiUrlPegasoTools,
    brokerUrl,
    brokerPort,
    brokerPrefix,
  }

  return (
    <SnackbarProvider maxSnack={3}>
      <RootContext.Provider value={defaultContext}>
        {children}
      </RootContext.Provider>
    </SnackbarProvider>
  )
}
