import React, { useEffect, useState, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"

//core components
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ErrorUtils from "components/Tools/ErrorUtils"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"
import { fetchWithToken } from "../../components/Fetch/api-fetch"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"

//Pegaso components
import FrameSuppliers from "views/FramesManagement/FrameSuppliers"
import FrameBrands from "views/FramesManagement/FrameBrands"
import FramesList from "views/FramesManagement/FramesList"
import FramesImportXMLFromWecolab from "views/FramesManagement/FramesImportXMLFromWecolab"

export default function Frames() {
  const { companyId, t, apiUrl, access_token, refresh_token, setAccess_token, getImportXMLFromWecoLab } = useContext(RootContext)
  const [frames, setFrames] = useState([])
  const [frameBrands, setFrameBrands] = useState([])
  const [frameBrandsFilterOption, setFrameBrandsFilterOption] = useState([])
  const [frameSuppliers, setFrameSuppliers] = useState([])
  const [frameSuppliersFilterOption, setFrameSuppliersFilterOption] = useState([])
  const [isFetchingFrames, setIsFetchingFrames] = useState(true)
  const [isFetchingFrameBrands, setIsFetchingFrameBrands] = useState(true)
  const [isFetchingFrameSuppliers, setIsFetchingFrameSuppliers] = useState(true)
  const [openPanelSupplier, setOpenPanelSupplier] = useState(false)
  const [openPanelBrand, setOpenPanelBrand] = useState(false)
  const [openPanelFrame, setOpenPanelFrame] = useState(false)
  const [openPanelImportXML, setOpenPanelImportXML] = useState(false)
  const [countFrameRows, setCountFramesRows] = useState(0)
  var importXMLFromWecoLab = getImportXMLFromWecoLab()

  /*
    HANDLER
  */


  //Chiude o apre il pannello contenente i supplier
  const changeOpenPanelFrameSupplier = (event,) => {
    var value = !openPanelSupplier
    setOpenPanelSupplier(value)
  }

  //Chiude o apre il pannello contenente i brand
  const changeOpenPanelFrameBrand = (event,) => {
    var value = !openPanelBrand
    setOpenPanelBrand(value)
  }

  //Chiude o apre il pannello contenente i brand
  const changeOpenPanelFrame = (event,) => {
    var value = !openPanelFrame
    setOpenPanelFrame(value)

  }

  //Chiude o apre il pannello contenente i brand
  const changeOpenPanelImportXML = (event,) => {
    var value = !openPanelImportXML
    setOpenPanelImportXML(value)

  }

  /**
   *
   * FETCH
   *
   */


  //Fetch dei frame suppliers
  const fetchFrameSuppliers = useCallback(() => {
    if (isFetchingFrameSuppliers === true) {
      fetchWithToken(`${apiUrl}/companies/${companyId}/frameSupplier`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { frameSuppliers } = body
          var newFrameSuppliers = frameSuppliers.map((option => {
            option.formattedActive = option.active ? t("10147") : t("10079")
            return option
          }))
          var optionFrameSupplier = frameSuppliers.map(option => {
            return {
              value: option.frameSupplierId,
              text: option.frameSupplierId + " - " + option.frameSupplierName,
            }
          })
          optionFrameSupplier.unshift({
            value: 0,
            text: ""
          })
          setFrameSuppliers(newFrameSuppliers)
          setFrameSuppliersFilterOption(optionFrameSupplier)
          setIsFetchingFrameSuppliers(false)
        })
        .catch(err => {
          setIsFetchingFrameSuppliers(false)
          ErrorUtils.errorLog("Fetch frame suppliers Error.", err.message)
        })
    }

  }, [access_token, apiUrl, companyId, isFetchingFrameSuppliers, refresh_token, setAccess_token, setIsFetchingFrameSuppliers, t])

  //Fetch dei frame brand
  const fetchFrameBrands = useCallback(() => {
    if (isFetchingFrameBrands === true) {
      fetchWithToken(`${apiUrl}/companies/${companyId}/frameBrand`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { frameBrands } = body
          var formattedBrands = frameBrands.map((brand) => {
            const { frameSupplier } = brand
            if (frameSupplier) {
              brand.formattedSupplier = frameSupplier.frameSupplierId + " - " + frameSupplier.frameSupplierName
              brand.frameSupplierId = frameSupplier.frameSupplierId
            }
            brand.formattedActive = brand.active ? t("10147") : t("10079")
            return brand
          })
          setFrameBrands(formattedBrands)
          var optionFrameBrands = frameBrands.map(option => {
            return {
              value: option.frameBrandId,
              text: option.frameBrandId + " - " + option.frameBrandName
            }
          })
          optionFrameBrands.unshift({
            value: 0,
            text: ""
          })
          setFrameBrandsFilterOption(optionFrameBrands)
          setIsFetchingFrameBrands(false)
        })
        .catch(err => {
          setIsFetchingFrameBrands(false)
          ErrorUtils.errorLog("Fetching frame brands Error.", err.message)
        })
    }
  }, [access_token, apiUrl, companyId, isFetchingFrameBrands, refresh_token, setAccess_token, t])

  //Fetch dei frame brand
  const fetchFrame = useCallback(() => {
    if (isFetchingFrames === true) {
      fetchWithToken(`${apiUrl}/companies/${companyId}/listFrames`, {
        method: "GET",
        headers: {
          Accept: +"application/json",
          "Content-Type": "application/json"
        },
        apiUrl: apiUrl,
        companyId: companyId,
        access_token: access_token,
        refresh_token: refresh_token,
        setAccess_token: setAccess_token
      })
        .then(response => {
          const body = response.body
          const { frames } = body
          setFrames(frames)
          setCountFramesRows(frames.length)
          setIsFetchingFrames(false)
        })
        .catch(err => {
          setIsFetchingFrames(false)
          ErrorUtils.errorLog("RequiredField Error.", err.message)
        })
    }
  }, [access_token, apiUrl, companyId, isFetchingFrames, refresh_token, setAccess_token,])

  /**
   * 
   *USE EFFECT
   */
  useEffect(() => {
    if (isFetchingFrameSuppliers || isFetchingFrameBrands) {
      fetchFrameSuppliers()
      fetchFrameBrands()
    }
    if (isFetchingFrames) {
      fetchFrame()
    }
  }, [isFetchingFrames, isFetchingFrameSuppliers, isFetchingFrameBrands, fetchFrameSuppliers, fetchFrameBrands, fetchFrame])


  return (
    <div>

      {/*PANNELLO DEI SUPPLIER*/}
      <Accordion expanded={openPanelSupplier} onChange={changeOpenPanelFrameSupplier} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10378").toUpperCase()}</u></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              {
                <FrameSuppliers
                  isFetchingFrameSuppliers={isFetchingFrameSuppliers}
                  tableLine={frameSuppliers}
                  setIsFetching={setIsFetchingFrameSuppliers}
                />
              }
            </CardBody>
          </Card>
        </AccordionDetails>
      </Accordion>

      {/*PANNELLO DEI BRAND*/}
      <Accordion expanded={openPanelBrand} onChange={changeOpenPanelFrameBrand} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10377").toUpperCase()}</u></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              {
                <FrameBrands
                  frameSuppliers={frameSuppliers}
                  isFetchingFrameBrands={isFetchingFrameBrands}
                  tableLine={frameBrands}
                  setIsFetching={setIsFetchingFrameBrands}
                />
              }
            </CardBody>
          </Card>
        </AccordionDetails>
      </Accordion>

      {/*PANNELLO DELLE MONTATURE*/}
      <Accordion expanded={openPanelFrame} onChange={changeOpenPanelFrame} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10376").toUpperCase()}</u></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Card>
            <CardBody>
              {
                <FramesList
                  framesRows={frames}
                  frameSuppliers={frameSuppliers}
                  frameSuppliersFilterOption={frameSuppliersFilterOption}
                  frameBrandsFilterOption={frameBrandsFilterOption}
                  frameBrands={frameBrands}
                  isFetchingFrames={isFetchingFrames}
                  setFramesRows={setFrames}
                  setIsFetchingFrames={setIsFetchingFrames}
                  setCountFramesRows={setCountFramesRows}
                  countFrameRows={countFrameRows}
                />
              }
            </CardBody>
          </Card>
        </AccordionDetails>
      </Accordion>

      {/*PANNELLO DELLE MONTATURE*/}
      {
        importXMLFromWecoLab ? (
          <Accordion expanded={openPanelImportXML} onChange={changeOpenPanelImportXML} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography align="center" style={{ width: "100%", alignItems: "center" }}><u>{t("10463").toUpperCase()}</u></Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Card>
                <CardBody>
                  {
                    <FramesImportXMLFromWecolab
                      frameSuppliers={frameSuppliers}
                      frameBrands={frameBrands}
                      isFetchingFrames={isFetchingFrames}
                      setIsFetchingFrames={setIsFetchingFrames}
                    />
                  }
                </CardBody>
              </Card>
            </AccordionDetails>
          </Accordion>
        ) : null
      }



    </div>
  )
}
