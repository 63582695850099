import StringUtils from "../Tools/StringUtils"
export default class JobsUtils {
  // Format Job Id
  static formatJobId(job) {
    return StringUtils.checkIfNotNull(job.jobId) === "" ? "" : job.jobId
    //Job.jobId.replace(/^0+/, ''); // Suprress leading zeros
  }

  // Format Status
  static formatJobStatus2(job, t) {
    if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "" ||
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "OPEN"
    ) {
      job.status = t("10090").toUpperCase()
    } else if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "CLOSED"
    ) {
      job.status = t("10011").toUpperCase()
    } else if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "WIP"
    ) {
      job.status = t("10146").toUpperCase()
    } else if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "ERROR"
    ) {
      job.status = t("43").toUpperCase()
    }
    return String.prototype.trim.call(job.status).toUpperCase()
  }


  static formatJobStatus(job, t) {
    if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "" ||
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "OPEN"
    ) {
      job.statusView = t("10090").toUpperCase()
    } else if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "CLOSED"
    ) {
      job.statusView = t("10011").toUpperCase()
    } else if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "WIP"
    ) {
      job.statusView = t("10146").toUpperCase()
    } else if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.status)) ===
      "ERROR"
    ) {
      job.statusView = t("43").toUpperCase()
    }
    return String.prototype.trim.call(job.statusView).toUpperCase()
  }


  // Format Optician
  static formatJobOptician(job) {
    const { opticianView } = job
    if (!opticianView) {
      let result = ""
      const { optician } = job
      if (optician) {
        const { id, name } = optician
        if (StringUtils.checkIfNotNull(id) !== "") {
          if (!id.includes("LAB")) {
            result = `${id} - ${String.prototype.trim.call(name)}`
          } else {
            //result = id
            result = `${id} - ${String.prototype.trim.call(name)}`
          }
        }
      }
      job.opticianView = result
    }
    return job.opticianView
  }

  // Format OpticianNote
  static formatJobOpticianNote(job) {
    const { note } = job
    if (!note) {
      let result = ""
      const { notes } = job
      if (notes) {
        const { opticianNote } = notes
        if (opticianNote) {
          if (String.prototype.trim.call(StringUtils.checkIfNotNull(opticianNote)) !== "") {
            // 2020/03/02 - Tronco le note massimo XX chars
            const maxChars = 50
            result = opticianNote
            if (result.length >= maxChars) {
              result = result.substring(0, maxChars) + "..."
            } else {
              result = result + ' '.repeat(maxChars)
              result = result.substring(0, maxChars)
            }
            result = String.prototype.trim.call(result)
          }
        }
      }
      job.note = result
    }
    return job.note
  }

  // Format opticianCustomer
  static formatJobOpticianCustomer(job) {
    const { optcst } = job
    if (!optcst) {
      let result = ""
      const { notes } = job
      if (notes) {
        const { opticianCustomer } = notes
        if (opticianCustomer) {
          if (String.prototype.trim.call(StringUtils.checkIfNotNull(opticianCustomer)) !== "") {
            // 2020/03/02 - Tronco le note massimo XX chars
            const maxChars = 50
            result = opticianCustomer
            if (result.length >= maxChars) {
              result = result.substring(0, maxChars) + "..."
            } else {
              result = result + ' '.repeat(maxChars)
              result = result.substring(0, maxChars)
            }
            result = String.prototype.trim.call(result)
          }
        }
      }
      job.optcst = result
    }
    return job.optcst
  }

  // Format Software
  static formatJobSoftware(job, t) {
    if (StringUtils.checkIfNotNull(job.software) === "") {
      job.software = ""
    } else if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.software)) ===
      "minigui"
    ) {
      job.software = t("10092")
    } else if (
      String.prototype.trim.call(StringUtils.checkIfNotNull(job.software)) ===
      "minihub"
    ) {
      job.software = t("10060")
    }
    return String.prototype.trim.call(job.software)
  }

  // Format date
  static formatJobCreationDate(job) {
    return StringUtils.checkIfNotNull(job.createdOn) === ""
      ? ""
      : job.createdOn.$date
  }

  static formatJobCreationDateFromString(job) {
    const { createdOn } = job
    if (createdOn) {
      return `${job.createdOn.substring(0, 10)} ${job.createdOn.substring(
        11,
        16
      )}`
    } else {
      return `${job.created.createdOn.substring(
        0,
        10
      )} ${job.created.createdOn.substring(11, 16)}`
    }
  }

  static formatJobUpdateDateFromString(job) {
    const { updatedOn } = job
    if (updatedOn) {
      return `${job.updatedOn.substring(0, 10)} ${job.updatedOn.substring(
        11,
        16
      )}`
    } else {
      return `${job.updated.updatedOn.substring(
        0,
        10
      )} ${job.updated.updatedOn.substring(11, 16)}`
    }
  }

}
