import CompressUtils from "components/Tools/CompressUtils"
import { useTranslation } from "react-i18next"
import JobsUtils from "components/Utils/JobsUtils"
import JobControlUtils from "components/Utils/JobControlUtils"
import ErrorUtils from "components/Tools/ErrorUtils"
import OmaUtils from "./OmaUtils"

/*************************************************************************
                  VALORI PER SELECT
 *************************************************************************/

export default class JobUtils {
  // Array con i codici e le descrizioni del TIPO LENTE in Oma
  static lensTypeValues() {
    return [
      {
        id: "",
        description: "",
      },
      {
        id: "AS",
        description: "5000",
      },
      {
        id: "AT",
        description: "5001",
      },
      {
        id: "BI",
        description: "5002",
      },
      {
        id: "CT",
        description: "5003",
      },
      {
        id: "DS",
        description: "5004",
      },
      {
        id: "EX",
        description: "5005",
      },
      {
        id: "FT",
        description: "5006",
      },
      {
        id: "LT",
        description: "5007",
      },
      {
        id: "PR",
        description: "5008",
      },
      {
        id: "QD",
        description: "5009",
      },
      {
        id: "RD",
        description: "5010",
      },
      {
        id: "SV",
        description: "5011",
      },
      {
        id: "TR",
        description: "5012",
      },
    ]
  }
  // Array con i codici e le descrizioni del TIPO BORDO in Oma  ETYP
  static edgingTypeValues() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: "",
      },
      {
        value: "1",
        label: t("10005"),
      },
      {
        value: "3",
        label: t("10042"),
      },
      {
        value: "2",
        label: t("10112"),
      },
    ]
  }
  // Array con i codici e le descrizioni del POSIZIONE BORDO in Oma
  static positionTypeValues() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: "",
      },
      {
        value: "7",
        label: t("10003"),
      },
      {
        value: "2",
        label: t("10212"),
      },
      {
        value: "4",
        label: "50/50",
      },
      {
        value: "5",
        label: t("10213"),
      },
      {
        value: "1",
        label: t("10211"),
      },
      {
        value: "0",
        label: t("10250"),
      },
      {
        value: "11",
        label: "TrueFit"
      }
    ]
  }
  // Array con i codici e le descrizioni della LUCIDATURA in Oma
  static polishValues() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: "",
      },
      {
        value: "0",
        label: t("10079"),
      },
      {
        value: "1",
        label: t("10147"),
      },
    ]
  }

  // Array con i codici e le descrizioni del MATERIALE MONTATURA in Oma FTYP
  static frameTypeValues() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: "",
      },
      {
        value: "1",
        label: t("10099"),
      },
      {
        value: "2",
        label: t("10074"),
      },
      {
        value: "3",
        label: t("10112"),
      },
      {
        value: "4",
        label: t("10095"),
      },
    ]
  }

  static statusValues() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: ""
      },
      {
        value: "OPEN",
        label: t("10090")
      },
      {
        value: "CLOSED",
        label: t("10011")
      },
      {
        value: "WIP",
        label: t("10146")
      },
      {
        value: "ERROR",
        label: t("43")
      },
    ]
  }

  // Array con i codici e le descrizioni del Materiale della lente in Oma
  static getMaterialLensTypes() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: "",
      },
      {
        value: "1",
        label: t("10393"),
      },
      {
        value: "2",
        label: t("10394"),
      },
      {
        value: "3",
        label: t("10395"),
      },
      {
        value: "4",
        label: t("10396"),
      },
      {
        value: "5",
        label: t("10397"),
      },
      {
        value: "6",
        label: t("10398"),
      },
      {
        value: "7",
        label: t("10399"),
      },
    ]
  }

  // Array con i codici e le descrizioni della scivolosità della lente in Oma
  static getSlipperyLensTypes() {
    const { t } = useTranslation()
    return [
      {
        value: "",
        label: "",
      },
      {
        value: "0",
        label: t("10302"),
      },
      {
        value: "1",
        label: t("10311"),
      },
      {
        value: "2",
        label: t("10312"),
      },
    ]
  }

  // Array con i valori dell'indice di rifrazione
  static getLensIndex() {
    return [
      {
        value: "",
        label: "",
      },
      {
        value: "1.500",
        label: "1.500",
      },
      {
        value: "1.530",
        label: "1.530",
      },
      {
        value: "1.560",
        label: "1.560",
      },
      {
        value: "1.570",
        label: "1.570",
      },
      {
        value: "1.589",
        label: "1.589",
      },
      {
        value: "1.600",
        label: "1.600",
      },
      {
        value: "1.670",
        label: "1.670",
      },
      {
        value: "1.700",
        label: "1.700",
      },
      {
        value: "1.740",
        label: "1.740",
      },
      {
        value: "1.800",
        label: "1.800",
      },
      {
        value: "1.900",
        label: "1.900",
      },
      {
        value: "2.000",
        label: "2.000",
      },
    ]
  }

  static getPrecalValue(){
    const { t } = useTranslation()
    return [
      {
        value: true,
        label: t("10147")
      },
      {
        value: false,
        label: t("10079")
      },
    ]
  }


  /*************************************************************************
                    FUNZIONE RECUPERO DATI ORDINE
   *************************************************************************/

  static GetStandardShape(job) {
    let result = ""
    const { shape } = job
    if (shape) {
      const { standard } = shape
      if (standard) {
        result = CompressUtils.zlibDecompress(standard)
      }
    }
    return result
  }

  static GetEdgingData(job) {
    let result = ""
    const { shape } = job
    if (shape) {
      const { edgingData } = shape
      if (edgingData) {
        result = edgingData
      }
    }
    return result
  }

  static GetOffset(job) {
    let result = ""
    const { shape } = job
    if (shape) {
      const { offset } = shape
      if (offset) {
        result = offset
      }
    }
    return result
  }

  static GetJobSoftware(job) {
    let result = ""
    const { created } = job
    if (created) {
      const { software } = created
      if (software) {
        result = software
      }
    }
    return result
  }

  // Crea lo stato iniziale della schermata
  static createDefaultState(jobData) {
    const edgingData = JobUtils.GetEdgingData(jobData)
    const arrayValue = edgingData.split("\r\n")

    var omaLabel = ""
    var omaValue = ""
    var dxSx = []
    var i

    const left = {
      side: "L",
      ltyp: "",
      dia: "",
      sph: "",
      ipd: "",
      cyl: "",
      ocht: "",
      ax: "",
      hbox: "",
      add: "",
      vbox: "",
      lmattype: "",
      _lcoat: "",
      thkp: "",
      cthick: "",
      fcrv: "",
      frnt: "",
      ztilt: "",
      lind: "",
      _seght: "",
      panto: "",
      prvm: "",
      prva: "",
    }

    const right = {
      side: "R",
      ltyp: "",
      dia: "",
      sph: "",
      ipd: "",
      cyl: "",
      ocht: "",
      ax: "",
      hbox: "",
      add: "",
      vbox: "",
      lmattype: "",
      _lcoat: "",
      thkp: "",
      cthick: "",
      fcrv: "",
      frnt: "",
      ztilt: "",
      lind: "",
      _seght: "",
      panto: "",
      prvm: "",
      prva: "",
    }

    const edging = {
      etyp: "",
      bevp: "",
      bevm: "",
      polish: "",
      ftyp: "",
      dbl: "",
      offset: JobUtils.GetOffset(jobData),
      drillingPoints: [],
      drillePoints: [],
      gwidth: "",
      gdepth: "",
      fpinb: "",
      pinb: "",
      do: "",
    }

    const { notes } = jobData
    const { created, updated } = jobData

    if (created) {
      let { hub } = created
      let { user } = created
      var insertby = ""
      if (hub) {
        insertby = `(lab ${jobData.lab.name})`
      }

      if (user) {
        insertby = `(${user.username})`
      }
    }

    if (updated) {
      var { hub: hubUpdate } = updated
      var { user: userUpdate } = updated

      var updateby = ""
      if (hubUpdate) {
        updateby = `(lab ${jobData.lab.name})`
      }

      if (userUpdate) {
        updateby = `(${userUpdate.username})`
      }
    }

    const { jobDevices } = jobData
    if (jobDevices) {
      var { uploader, downloader } = jobDevices

      if (uploader) {
        uploader = uploader.model
      }

      if (downloader) {
        downloader = downloader.model
      }
    }
    const { company } = jobData
    if (company) {
      var head = {
        company: {
          id: jobData.company.id,
          name: jobData.company.name,
        },
        lab: {
          id: jobData.lab.id,
          name: jobData.lab.name,
        },
        optician: {
          id: jobData.optician.id,
          name: jobData.optician.name,
        },
        jobnumber: jobData.jobId,
        date: JobsUtils.formatJobCreationDateFromString(jobData),
        update: JobsUtils.formatJobUpdateDateFromString(jobData),
        status: jobData.status,
        insertby: insertby,
        updateby: updateby,
        deviceUploader: uploader,
        deviceDownloader: downloader,
      }
    }
    for (i = 0; i < arrayValue.length; i++) {
      omaLabel = arrayValue[i].substring(0, arrayValue[i].indexOf("=", 0))
      omaValue = arrayValue[i].substring(arrayValue[i].indexOf("=", 0) + 1)
      switch (omaLabel) {
        // Valori singoli
        case "DBL":
          edging.dbl = omaValue
          break
        case "ETYP":
          edging.etyp = omaValue
          break
        case "BEVP":
          edging.bevp = omaValue
          break
        case "BEVM":
          edging.bevm = omaValue
          break
        case "POLISH":
          edging.polish = omaValue
          break
        case "FTYP":
          edging.ftyp = omaValue
          break
        case "DRILL":
          edging.drillingPoints.push(omaValue)
          break
        case "DRILLE":
          edging.drillePoints.push(omaValue)
          break
        case "DO":
          edging.do = omaValue
          break
        // Valori DX;SX
        case "LTYP":
          dxSx = omaValue.split(";")
          right.ltyp = dxSx[0]
          left.ltyp = dxSx[1]
          break
        case "DIA":
          dxSx = omaValue.split(";")
          right.dia = JobUtils.formatDiameter(dxSx[0])
          left.dia = JobUtils.formatDiameter(dxSx[1])
          break
        case "SPH":
          dxSx = omaValue.split(";")
          right.sph = JobUtils.formatSphereCylinder(dxSx[0])
          left.sph = JobUtils.formatSphereCylinder(dxSx[1])
          break
        case "CYL":
          dxSx = omaValue.split(";")
          right.cyl = JobUtils.formatSphereCylinder(dxSx[0])
          left.cyl = JobUtils.formatSphereCylinder(dxSx[1])
          break
        case "AX":
          dxSx = omaValue.split(";")
          right.ax = JobUtils.formatAxis(dxSx[0])
          left.ax = JobUtils.formatAxis(dxSx[1])
          break
        case "ADD":
          dxSx = omaValue.split(";")
          right.add = JobUtils.formatGeneric(dxSx[0])
          left.add = JobUtils.formatGeneric(dxSx[1])
          break
        case "IPD":
          dxSx = omaValue.split(";")
          right.ipd = JobUtils.formatGeneric(dxSx[0])
          left.ipd = JobUtils.formatGeneric(dxSx[1])
          break
        case "OCHT":
          dxSx = omaValue.split(";")
          right.ocht = JobUtils.formatGeneric(dxSx[0])
          left.ocht = JobUtils.formatGeneric(dxSx[1])
          break
        case "HBOX":
          dxSx = omaValue.split(";")
          right.hbox = JobUtils.formatGeneric(dxSx[0])
          left.hbox = JobUtils.formatGeneric(dxSx[1])
          break
        case "VBOX":
          dxSx = omaValue.split(";")
          right.vbox = JobUtils.formatGeneric(dxSx[0])
          left.vbox = JobUtils.formatGeneric(dxSx[1])
          break
        case "LMATTYPE":
          dxSx = omaValue.split(";")
          right.lmattype = dxSx[0]
          left.lmattype = dxSx[1]
          break
        case "_LCOAT":
          dxSx = omaValue.split(";")
          right._lcoat = dxSx[0]
          left._lcoat = dxSx[1]
          break
        case "THKP":
          dxSx = omaValue.split(";")
          right.thkp = JobUtils.formatGeneric(dxSx[0])
          left.thkp = JobUtils.formatGeneric(dxSx[1])
          break
        case "CTHICK":
          dxSx = omaValue.split(";")
          right.cthick = JobUtils.formatGeneric(dxSx[0])
          left.cthick = JobUtils.formatGeneric(dxSx[1])
          break
        case "FPINB":
          dxSx = omaValue.split(";")
          edging.fpinb = JobUtils.formatGeneric(dxSx[0])
          break
        case "PINB":
          dxSx = omaValue.split(";")
          edging.pinb = JobUtils.formatGeneric(dxSx[0])
          break
        case "GWIDTH":
          dxSx = omaValue.split(";")
          edging.gwidth = JobUtils.formatGeneric(dxSx[0])
          break
        case "GDEPTH":
          dxSx = omaValue.split(";")
          edging.gdepth = JobUtils.formatGeneric(dxSx[0])
          break
        case "FCRV":
          dxSx = omaValue.split(";")
          right.fcrv = JobUtils.formatGeneric(dxSx[0])
          left.fcrv = JobUtils.formatGeneric(dxSx[1])
          break
        case "FRNT":
          dxSx = omaValue.split(";")
          right.frnt = JobUtils.formatGeneric(dxSx[0])
          left.frnt = JobUtils.formatGeneric(dxSx[1])
          break
        case "ZTILT":
          dxSx = omaValue.split(";")
          right.ztilt = JobUtils.formatGeneric(dxSx[0])
          left.ztilt = JobUtils.formatGeneric(dxSx[1])
          break
        case "LIND":
          dxSx = omaValue.split(";")
          right.lind = dxSx[0]
          left.lind = dxSx[1]
          break
        case "_SEGHT":
          dxSx = omaValue.split(";")
          right._seght = JobUtils.formatGeneric(dxSx[0])
          left._seght = JobUtils.formatGeneric(dxSx[1])
          break
        case "PANTO":
          dxSx = omaValue.split(";")
          right.panto = JobUtils.formatGeneric(dxSx[0])
          left.panto = JobUtils.formatGeneric(dxSx[1])
          break
        case "BVD":
          dxSx = omaValue.split(";")
          right.bvd = JobUtils.formatGeneric(dxSx[0])
          left.bvd = JobUtils.formatGeneric(dxSx[1])
          break
        case "PRVM":
            dxSx = omaValue.split(";")
            right.prvm = JobUtils.formatGeneric(dxSx[0])
            left.prvm = JobUtils.formatGeneric(dxSx[1])
            break
        case "PRVA":
            dxSx = omaValue.split(";")
            right.prva = JobUtils.formatAxis(dxSx[0])
            left.prva = JobUtils.formatAxis(dxSx[1])
            break
        default:
          break
      }
    }
    const status = company
      ? {
        rightValues: right,
        leftValues: left,
        edgingValues: edging,
        notesValues: notes,
        headValues: head,
      }
      : {
        rightValues: right,
        leftValues: left,
        edgingValues: edging,
        notesValues: notes,
      }

    return status
  }

  // Creo array con i nuovi dati montaggio in OMA dei dati con label=dx;sx
  static createRightLeftOmaEdgingData(rightData, leftData) {
    var newOmaData = []

    newOmaData.push(
      OmaUtils.CreateOmaRecord("LTYP", rightData.ltyp, leftData.ltyp, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("DIA", rightData.dia, leftData.dia, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("SPH", rightData.sph, leftData.sph, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("CYL", rightData.cyl, leftData.cyl, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("AX", rightData.ax, leftData.ax, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("ADD", rightData.add, leftData.add, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("IPD", rightData.ipd, leftData.ipd, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("OCHT", rightData.ocht, leftData.ocht, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("HBOX", rightData.hbox, leftData.hbox, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("VBOX", rightData.vbox, leftData.vbox, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("LMATTYPE", rightData.lmattype, leftData.lmattype, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("_LCOAT", rightData._lcoat, leftData._lcoat, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("THKP", rightData.thkp, leftData.thkp, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("CTHICK", rightData.cthick, leftData.cthick, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("FCRV", rightData.fcrv, leftData.fcrv, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("FRNT", rightData.frnt, leftData.frnt, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("ZTILT", rightData.ztilt, leftData.ztilt, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("LIND", rightData.lind, leftData.lind, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("_SEGHT", rightData._seght, leftData._seght, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("PANTO", rightData.panto, leftData.panto, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("BVD", rightData.bvd, leftData.bvd, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("PRVM", rightData.prvm, leftData.prvm, false)
    )
    newOmaData.push(
      OmaUtils.CreateOmaRecord("PRVA", rightData.prva, leftData.prva, false)
    )
    return newOmaData.filter(function (el) {
      return el !== ""
    })
  }

  // Creo array con i nuovi dati montaggio in OMA dei dati con label=val
  static createSingleOmaEdgingData(rightData) {
    var newOmaData = []
    newOmaData.push(OmaUtils.CreateOmaRecord("ETYP", rightData.etyp, "", true))
    newOmaData.push(OmaUtils.CreateOmaRecord("BEVP", rightData.bevp, "", true))
    if (rightData.bevp !== "7" && rightData.bevp !== "") {
      newOmaData.push(
        OmaUtils.CreateOmaRecord("BEVM", rightData.bevm, "", true)
      )
    }
    newOmaData.push(
      OmaUtils.CreateOmaRecord("POLISH", rightData.polish, "", true)
    )
    newOmaData.push(OmaUtils.CreateOmaRecord("FTYP", rightData.ftyp, "", true))
    newOmaData.push(OmaUtils.CreateOmaRecord("DBL", rightData.dbl, "", true))

    //Valori gestiti come singoli ma OMA sono sx e dx
    newOmaData.push(OmaUtils.CreateOmaRecord("GWIDTH", rightData.gwidth, rightData.gwidth, false))
    newOmaData.push(OmaUtils.CreateOmaRecord("GDEPTH", rightData.gdepth, rightData.gdepth, false))
    newOmaData.push(OmaUtils.CreateOmaRecord("FPINB", rightData.fpinb, rightData.fpinb, false))
    newOmaData.push(OmaUtils.CreateOmaRecord("PINB", rightData.pinb, rightData.pinb, false))
    newOmaData.push(OmaUtils.CreateOmaRecord("DO", rightData.do, "", true))

    if (rightData.drillingPoints.length > 0) {
      for (var i = 0; i < rightData.drillingPoints.length; i++) {
        newOmaData.push(
          OmaUtils.CreateOmaRecord(
            "DRILL",
            rightData.drillingPoints[i],
            "",
            true
          )
        )
      }
    }

    if (rightData.drillePoints.length > 0) {
      for (var j = 0; j < rightData.drillePoints.length; j++) {
        newOmaData.push(
          OmaUtils.CreateOmaRecord(
            "DRILLE",
            rightData.drillePoints[j],
            "",
            true
          )
        )
      }
    }
    return newOmaData.filter(function (el) {
      return el !== ""
    })
  }

  //Formatta la forma nel modo corretto per esportre la forma su file.
  static convertshapeToExport(currentShape) {
    var separator = "\r\n"
    var line
    var newShape = ""
    var shapeArray = currentShape.split(separator)
    for (var i = 0; i < shapeArray.length; i++) {
      line = shapeArray[i].split("=")[0]
      switch (line.split("=")[0]) {
        case ("TRCFMT"):
          newShape += shapeArray[i] + separator
          break
        case ("R"):
          newShape += shapeArray[i] + separator
          break
        case ("ZFMT"):
          newShape += shapeArray[i] + separator
          break
        case ("Z"):
          newShape += shapeArray[i] + separator
          break
        default:
          break
      }
    }
    return newShape
  }



  /*************************************************************************
                    FUNZIONE FORMATTAZIONE/CONTROLLO NUMERI
   *************************************************************************/

  // Formattazione Numero
  static number_format(number, decimals, dec_point, thousands_sep) {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "")
    var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
      dec = typeof dec_point === "undefined" ? "." : dec_point,
      s = "",
      toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec)
        return "" + (Math.round(n * k) / k).toFixed(prec)
      }
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".")
    if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || "").length < prec) {
      s[1] = s[1] || ""
      s[1] += new Array(prec - s[1].length + 1).join("0")
    }
    return s.join(dec)
  }

  // Controlla che un numero sia numerico
  static isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  }

  // Controlla che un numero sia intero
  static numberIsIntger(n) {
    if (String(n).indexOf(".") !== -1 || String(n).indexOf(",") !== -1) {
      return false
    }
    return true
  }

  // Controlla che un numero sia divisibile per 0.25
  static zeroTwentyFive(n) {
    if (n % 0.25 > 0) {
      return false
    }
    return true
  }

  // Formatta diametro
  static formatDiameter(value) {
    var newValue = value
    if (value === "?" || value.trim() === "") {
      return ""
    } else {
      if (!JobUtils.isNumeric(value)) {
        return ""
      }
    }
    return newValue
  }

  // Formatta sfero e cilindro
  static formatSphereCylinder(value) {
    var newValue = value
    var segno = ""
    value = value.replace(",", ".")
    if (value === "?" || value.trim() === "") {
      return ""
    } else {
      if (JobUtils.isNumeric(value)) {
        if (value.indexOf("+") >= 0 || value.indexOf("-") >= 0) {
          segno = value.charAt(0)
          value = value.substr(1)
        } else {
          segno = "+"
        }
        if (value.charAt(0) === "0") {
          if (value.indexOf(".") < 0) {
            value = value.charAt(0) + "." + value.substr(1)
          }
          value = JobUtils.number_format(parseFloat(value), 2, ".", "") + ""
          newValue = segno + value
        } else {
          value = parseFloat(value)
          if (value >= 100) {
            value = value / 100
          }
          value = JobUtils.number_format(value, 2, ".", "") + ""
          newValue = segno + value
        }
      }
    }
    return newValue
  }

  // Formatta asse
  static formatAxis(value) {
    var newValue = value
    if (newValue.trim() !== "") {
      if (value === "?") {
        newValue = ""
      } else {
        newValue = JobUtils.number_format(newValue, 0)
      }
    }
    return newValue
  }

  // Formattazione generica che sostituisce la , con il punto e usa 2 cifre decimali
  static formatGeneric(value) {
    var newValue = value
    if (value) {
      value = value.replace(",", ".")
      if (newValue.trim() !== "") {
        if (newValue === "?") {
          newValue = ""
        } else {
          if (JobUtils.isNumeric(value)) {
            value = parseFloat(value)
            if (value >= 100) {
              value = value / 100
            }
            newValue = JobUtils.number_format(value, 2, ".", "") + ""
          }
        }
      }
    }
    return newValue
  }

  /*************************************************************************
                  FUNZIONE REQUIRED + CHECKS AUTOMATICI
   *************************************************************************/

  static fieldIsRequired(id, requiredFields, isGlasant) {
    let result = false
    requiredFields.forEach((element) => {
      if (!((element === "bevm" || element === "bevp") && isGlasant)) {
        var posUnderscore = element.indexOf("_")
        var first, second
        if (posUnderscore > 0) {
          first = element.substr(0, posUnderscore)
          second = element.substr(posUnderscore + 1)
          if (id.indexOf(first) >= 0) {
            result = true
          } else if (id.indexOf(second) >= 0) {
            result = true
          }
        } else {
          if (id.indexOf(element) >= 0) {
            result = true
          }
        }
      }
    })
    return result
  }

  static getBevpMeasure(fieldsToCheck) {
    var result = " (mm)"
    for (var [key, value] of Object.entries(fieldsToCheck)) {
      switch (key) {
        case "bevp":
          if (value === "2" || value === "4") {
            result = " (%)"
          }
          break
        default:
          break
      }
    }
    return result
  }

  static checkAllRequiredCompiled(fieldsToCheck, requiredFields, isGlasant, edgingEightType, isPersonalized) {
    let result = true
    for (var [key, value] of Object.entries(fieldsToCheck)) {
      if (JobUtils.fieldIsRequired(key, requiredFields, isGlasant, isPersonalized)) {
        if (key === "ocht") {
          if (edgingEightType === "BOX") {
            if (value === "" || value === null) {
              result = false
              return result
            }
          }
        }
        else if (key === "_seght") {
          if (edgingEightType === "EDGE") {
            if (value === "" || value === null) {
              result = false
              return result
            }
          }
        }
        else if (key === "bvd" || key === "panto" || key === "ztilt") {
          if(isPersonalized) {
            if (value === "" || value === null) {
              result = false
              return result
            }
          }
        }
        else {
          if (value === "" || value === null) {
            result = false
            return result
          }
        }
      }
    }
    return result
  }

  static checkFormatEdgingData(fieldsToCheck, isGlasant, t) {
    let result = true
    for (var [key, value] of Object.entries(fieldsToCheck)) {
      let check
      switch (key) {
        case "bevm":
          if (!isGlasant) {
            const unitaDiMisura = JobUtils.getBevpMeasure(fieldsToCheck).trim()
            check = (v, t) =>
              JobControlUtils.controlBevm(
                v.substr(0, v.indexOf(";")),
                unitaDiMisura,
                t
              )
          } else {
            check = (v, t) => true
          }
          break
        case "dbl":
          check = JobControlUtils.controlDbl
          break
        case "offset":
          check = JobControlUtils.controlOffset
          break
        case "fpinb":
          check = JobControlUtils.controlPinb
          break
        default:
          check = (v, t) => true
          break
      }
      const c = check(value, t)
      const { error, msgError } = c
      if (error) {
        ErrorUtils.errorLogKey("checkFormatEdgingData", key, msgError)
        result = false
        return result
      }
    }
    return result
  }

  static checkFormatLens(fieldsToCheck, t) {
    let result = true
    for (var [key, value] of Object.entries(fieldsToCheck)) {
      let check
      switch (key) {
        case "dia":
          check = JobControlUtils.controlDiameter
          break
        case "sph":
          check = JobControlUtils.controlSphere
          break
        case "cyl":
          check = JobControlUtils.controlCylinder
          break
        case "ax":
          check = JobControlUtils.controlAxis
          break
        case "add":
          check = JobControlUtils.controlAddition
          break
        case "ipd":
          check = JobControlUtils.controlPd
          break
        case "ocht":
          check = JobControlUtils.controlEdgingHeight
          break
        case "hbox":
          check = JobControlUtils.controlBox
          break
        case "vbox":
          check = JobControlUtils.controlBox
          break
        case "thkp":
          check = JobControlUtils.controlEdgeThickness
          break
        case "cthick":
          check = JobControlUtils.controlCenterThickness
          break
        case "_seght":
          check = JobControlUtils.controlEdgingHeight
          break
        case "prvm":
          check = JobControlUtils.controlPrvm
          break
        case "prva":
          check = JobControlUtils.controlPrva
          break
        default:
          check = (v, t) => true
          break
      }
      const c = check(value, t)
      const { error, msgError } = c
      if (error) {
        ErrorUtils.errorLogKey("checkFormatLens", key, msgError)
        result = false
        return result
      }
    }
    return result
  }
}
