import React, { useState, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"
import { useDropzone } from "react-dropzone"

// core components
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

// CSS
import { useJobsStyles } from "styles/jobs/jobsCss"
import { useJobStylesText } from "styles/job/jobCss"

//Pegaso component
import { fetchWithToken } from "components/Fetch/api-fetch"
import CompressUtils from "components/Tools/CompressUtils"
import { fetchWithTokenPegasoTools } from "components/Fetch/tools-api-fetch"

import ErrorUtils from "components/Tools/ErrorUtils"

export default function FramesList(props) {
  const { companyId, t, apiUrl, access_token, refresh_token, setAccess_token, apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools } = useContext(RootContext)
  const { frameBrands, frameSuppliers, } = props

  const classesText = useJobStylesText()
  const classes = useJobsStyles()

  const [fetching, setFetching] = useState(false)
  const [frameSupplierId, setFrameSupplierId] = useState(0)
  const [frameBrandId, setFrameBrandId] = useState(0)
  const [filterFrameBrands, setFilterFrameBrands] = useState(frameBrands)
  const [filesToImport, setFilesToImport] = useState([])
  const [isSelectedSupplierAndBrand, setIsSelectedSupplierAndBrand] = useState(false)
  const [isSelectedFolder, setIsSelectedFolder] = useState(false)
  const [numberFilesToImport, setNumberFilesToImport] = useState(0)
  const [numberFilesConverted, setNumberFilesConverted] = useState(0)
  const [numberFilesNotConverted, setNumberFilesNotConverted] = useState(0)


  const FolderDropzone = ({ onFilesAdded, setFilesToImport, setIsSelectedFolder, setNumberFilesToImport, isSelectedFolder, setNumberFilesConverted, setNumberFilesNotConverted }) => {
    const onDrop = useCallback((acceptedFiles) => {
      setFilesToImport(acceptedFiles)
      if (onFilesAdded) {
        onFilesAdded(acceptedFiles);
      }
      setIsSelectedFolder(true)
      setNumberFilesToImport(acceptedFiles.length)
      setNumberFilesConverted(0)
      setNumberFilesNotConverted(0)
    }, [onFilesAdded, setFilesToImport, setIsSelectedFolder, setNumberFilesToImport, setNumberFilesConverted, setNumberFilesNotConverted]);

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: false,
      directory: true,
      multiple: true,
    });

    return (
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #ccc",
          padding: 4,
          textAlign: "center",
          cursor: "pointer",
          borderRadius: 2,
          backgroundColor: !isSelectedFolder ? "#f0f0f0" : "#a2f759"
        }}
      >
        {/* Input nascosto per la selezione manuale */}
        <input {...getInputProps()} webkitdirectory="" directory="" />

        <Typography variant="h6">{t("10457")}</Typography>
        {
          isSelectedFolder ? (<Typography variant="body2">{t("10458")}</Typography>) : (null)
        }
      </Box>
    );
  };


  /*************************************************************************
                      HANDLER CHANGER
   **************************************************************************/

  //Cambia field frame supplier
  const handleChangeFrameSupplier = (event) => {
    setNumberFilesConverted(0)
    setNumberFilesNotConverted(0)
    setFilesToImport([])
    setIsSelectedFolder(false)
    var filterBrands = frameBrands
    setFrameSupplierId(event.target.value)
    filterBrands = filterBrands.filter(option => option.frameSupplier.frameSupplierId === event.target.value)
    setFilterFrameBrands(filterBrands)
  }

  //Cambia field frame brand
  const handleChangeFrameBrand = (event) => {
    setNumberFilesConverted(0)
    setNumberFilesNotConverted(0)
    setFilesToImport([])
    setIsSelectedFolder(false)
    setFrameBrandId(event.target.value)
    setIsSelectedSupplierAndBrand(true)
  }

  const handleImportFile = async (event) => {
    setNumberFilesConverted(0);
    setNumberFilesNotConverted(0);
    const fileReadPromises = filesToImport.map((element) => {
      return new Promise((resolve, reject) => {
        setFetching(true);
        const reader = new FileReader();
        reader.onload = async (event) => {
          var contents = {};
          contents[element.name] = event.target.result;
          var fileName = element.name.split('.')[0];
          var splitFileName = fileName.split("_");
          var modelName = splitFileName[0];
          var caliberIndex = fileName.indexOf("_CAL") > 0 ? fileName.indexOf("_CAL") + 4 : -1;
          var caliber = caliberIndex >= 0 ? fileName.substring(caliberIndex) : 0;

          try {
            await convertXMLToOMA(contents[element.name], modelName, caliber, element.path);
            resolve(); // Completa la promise
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsText(element);
      });
    });
    await Promise.all(fileReadPromises); // Aspetta che tutti i file siano stati processati
  };



  const handleClickImportFile = async () => {
    await handleImportFile();
    setFetching(false)
  };

  /*************************************************************************
                      FETCH
   **************************************************************************/
  const convertXMLToOMA = useCallback(async (xmlFileContent, modelName, caliber, fileName) => {
    try {
      const response = await fetchWithTokenPegasoTools(`${apiUrlPegasoTools}/ShapeConverter/wecolab-shape-to-oma`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/xml",
        },
        body: xmlFileContent,
        apiUrl_pegaso_tools: apiUrlPegasoTools,
        access_token_pegaso_tools: access_token_pegaso_tools,
        refresh_token_pegaso_tools: refresh_token_pegaso_tools,
        setAccess_token_pegaso_tools: setAccess_token_pegaso_tools,
      });

      const result = await response.body; // Aspettiamo il risultato della conversione
      if (result.shapeIn !== "") {
        const frameId = String(Math.floor(10 ** 12 + Math.random() * 9 * 10 ** 12));
        const shapeCompressed = CompressUtils.zlibCompress(result.original);
        const urlMiniManager = `${apiUrl}/companies/${companyId}/frame`;

        try {
          const frameResponse = await fetchWithToken(urlMiniManager, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              frameBrandId: frameBrandId,
              frameId: frameId,
              modelName: modelName,
              caliber: parseInt(caliber),
              active: true,
              shape: {
                original: shapeCompressed,
                standard: shapeCompressed,
                edgingData: result.edgingData,
                offset: 0,
              },
            }),
            apiUrl: apiUrl,
            companyId: companyId,
            access_token: access_token,
            refresh_token: refresh_token,
            setAccess_token: setAccess_token,
          });

          await frameResponse.body; // Aspettiamo il risultato del salvataggio

          setNumberFilesConverted(prevCount => prevCount + 1);
        } catch (err) {
          setNumberFilesNotConverted(prevCount => prevCount + 1);
          ErrorUtils.errorLog("frameBrandPutError", err);
          ErrorUtils.errorLog("File non convertito ", fileName);
        }
      }
    } catch (error) {
      setNumberFilesNotConverted(prevCount => prevCount + 1);
      ErrorUtils.errorLog("Login", error);
      ErrorUtils.errorLog("File non convertito ", fileName);
    }
  }, [apiUrlPegasoTools, access_token_pegaso_tools, frameBrandId, access_token, apiUrl, companyId, refresh_token, setAccess_token, refresh_token_pegaso_tools, setAccess_token_pegaso_tools]);

  return (
    <div>
      <Card>
        <CardBody>
          <Grid container spacing={2}>
            <Grid className={classes.gridCurrentValues} item xs={12}>
              <Grid
                item
                xs={12}
                container
                justifyContent="flex-start"
                alignItems="center"
              >

                <Grid item xs={3}>
                  <TextField
                    select
                    className={classesText.textField}
                    id="frameSupplier"
                    label={t("10330")}
                    value={frameSupplierId}
                    onChange={handleChangeFrameSupplier}
                  >
                    <MenuItem key={0} value={0} />
                    {frameSuppliers.map((option) => (

                      <MenuItem key={option.frameSupplierId} value={option.frameSupplierId} name={option.frameSupplierName}>
                        {option.frameSupplierId + " - " + option.frameSupplierName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    select
                    className={classesText.textField}
                    id="frameBrand"
                    label={t("10332")}
                    value={frameBrandId}
                    onChange={handleChangeFrameBrand}
                    disabled={frameSupplierId === 0}
                  >
                    <MenuItem key={0} value={0} />
                    {filterFrameBrands.map((option) => (

                      <MenuItem key={option.frameBrandId} value={option.frameBrandId} name={option.frameBrandName}>
                        {option.frameBrandId + " - " + option.frameBrandName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={3}>
                  {
                    isSelectedSupplierAndBrand ? (
                      <FolderDropzone
                        isSelectedFolder={isSelectedFolder}
                        setFilesToImport={setFilesToImport}
                        setIsSelectedFolder={setIsSelectedFolder}
                        setNumberFilesToImport={setNumberFilesToImport}
                        setNumberFilesConverted={setNumberFilesConverted}
                        setNumberFilesNotConverted={setNumberFilesNotConverted}
                      />
                    ) : null
                  }
                </Grid>

                {/*Bottone per conversione file*/}
                {
                  isSelectedFolder && isSelectedSupplierAndBrand ? (
                    <Grid item xs={3} container alignItems="center">
                      <Grid item xs={12} align="center">
                        <p align="center">
                          <u>{t("10459").toUpperCase()}</u>
                        </p>
                      </Grid>
                      {
                        fetching ? (
                          <Grid item xs={12} align="center">
                            <CircularProgress size={42} style={{ color: "primary" }} />
                          </Grid>

                        ) : (
                          <Grid item xs={12} align="center">
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              onClick={handleClickImportFile}
                              endIcon={<PlayCircleOutlineIcon />}
                              disabled={!isSelectedFolder || !isSelectedSupplierAndBrand}
                              style={{ textAlign: "center" }}
                            >
                              {t("10460")}
                            </Button>
                          </Grid>

                        )
                      }
                    </Grid>
                  ) : (
                    <Grid item xs={3} />
                  )
                }

                <Grid item xs={3} />
                <Grid item xs={3} />
                <Grid item xs={3} />

                {/*Bottone per conversione file*/}
                {
                  numberFilesToImport && (numberFilesConverted > 0 || numberFilesNotConverted > 0) ? (
                    <Grid item xs={3} align="center">
                      <p align="center">
                        <u>{t("10461").toUpperCase() + " " + numberFilesConverted + " su " + numberFilesToImport}</u>
                      </p>
                      {
                        numberFilesNotConverted > 0 ? (<p align="center" style={{ color: 'red' }}>
                          <u>{numberFilesNotConverted + " " + t("10462").toUpperCase()}</u>
                        </p>) : null
                      }
                    </Grid>
                  ) : (
                    null
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </div>
  )
}