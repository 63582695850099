import React, { useState, useContext, useCallback } from "react"
import { RootContext } from "../../RootContext"

//core components
import Button from '@material-ui/core/Button';
import CircularProgress from "@material-ui/core/CircularProgress"
import { DropzoneArea } from 'material-ui-dropzone'
import ErrorUtils from "components/Tools/ErrorUtils"
import FileSaver from "file-saver"
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid"
import GetAppIcon from '@material-ui/icons/GetApp';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Select from "@material-ui/core/Select"

// CSS 
import { useDropzoneAreaStyle,useGridStyle } from "styles/autocadConverter/autocadConverterCss"

export default function FromOMAToDXF() {
  const { t, apiUrlPegasoTools, access_token_pegaso_tools, } = useContext(RootContext)
  const [fetching, setFetching] = useState(false)
  const [fileChargedData, setFileChargedData] = useState([])
  const [fileConverted, setFileConverted] = useState(undefined)
  const [fileName, setFileName] = useState("")
  const [isError, setIsError] = useState("")
  const [message, setMessage] = useState("")
  const [side, setSide] = useState("")
  const [selectedSide, setSelectedSide] = useState(false)

  const dropzoneClasses = useDropzoneAreaStyle()
  const gridClasses = useGridStyle()

  //Handler change del file inserito
  function handleChangeFile(files) {
    setFileName("")
    setMessage("")
    setIsError(false)
    setFileConverted(undefined)
    setFileChargedData(files)
  }

  //Post in PegasoTools per la conversione del file
  const convertOMAToDXF = useCallback(() => {
    const formData = new FormData()
    formData.append("fileoma", fileChargedData[0])
    fetch(`${apiUrlPegasoTools}/ShapeConverter/oma-to-dxf?side=${side}`, {
      method: "post",
      headers: {
        'Authorization': 'Bearer ' + access_token_pegaso_tools
      },
      body: formData,
    })
      .then(response => {
        if (!response.ok) throw Error(response.status)
        else return response.text()
      })
      .then(result => {
        let shape = Buffer.from(result, "base64").toString();
        var blob = new Blob([`${shape}`], {
          type: "text/plain;charset=utf-8",
        })
        var fileWithoutExtension = fileChargedData[0].name.split('.')
        setFileConverted(blob)
        setFileName(fileWithoutExtension[0])
        setMessage(t("10359").toUpperCase())
        setFetching(false)
      })
      .catch(error => {
        setMessage(t("10360").toUpperCase())
        setIsError(true)
        ErrorUtils.errorLog("Login", error)
        setFetching(false)
      });
  }, [apiUrlPegasoTools, fileChargedData, t, side, access_token_pegaso_tools])

 
  //Handler click bottone converti
  const handleClickConvert = () => {
    setFetching(true)
    setMessage("")
    setFileName("")
    setIsError(false)
    convertOMAToDXF()
  }

  //Handler click donwload file
  const downloadFileConverted = () => {
    FileSaver.saveAs(fileConverted, `${fileName}.dxf`)
  }

  //Handler lato della lente
  const handleChangeSide = (event) => {
    setSide(event.target.value);
    setSelectedSide(true)
  };

  return (

    <Grid container spacing={2} className={gridClasses.cardMarginBottom}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        
        {/*Selezione lato della lente*/}
        <Grid item xs={3} container alignItems="center">
          <Grid item xs={12}>
            <p align="center">
              <u>{t("10368").toUpperCase()}</u>
            </p>
          </Grid>
          <Grid item xs={12} align="center">
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel >{t("10385").toUpperCase()}</InputLabel>
              <Select
                value={side}
                label="Age"
                onChange={handleChangeSide}
              >
                <MenuItem value={'R'}>{t("10351")}</MenuItem>
                <MenuItem value={'L'}>{t("10352")}</MenuItem>
                <MenuItem value={'B'}>{t("10369")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/*DropzoneArea per inserimento file*/}
        <Grid item xs={3}>
          {
            selectedSide ? (
              <DropzoneArea
                acceptedFiles={['.oma', '.dat',  '.txt']}
                dropzoneClass={dropzoneClasses.myDropZone}
                dropzoneParagraphClass={dropzoneClasses.dropZoneText}
                dropzoneText={t("10363")}
                filesLimit={1}
                onChange={handleChangeFile.bind(this)}
                maxFileSize={10000000}
                useChipsForPreview={true}
                showFileNamesInPreview={true}
              />
            ) : (null)
          }
        </Grid>

        {/*Bottone per conversione file*/}
        {
          selectedSide && fileChargedData.length > 0 ? (
            <Grid item xs={3} container alignItems="center">
              <Grid item xs={12} align="center">
                <p align="center">
                  <u>{t("10364").toUpperCase()}</u>
                </p>
              </Grid>
              {
                fetching ? (
                  <Grid item xs={12} align="center">
                    <CircularProgress size={42} style={{ color: "primary" }} />
                  </Grid>

                ) : (
                  <Grid item xs={12} align="center">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleClickConvert}
                      endIcon={<PlayCircleOutlineIcon />}
                      disabled={fileChargedData.length <= 0 || !selectedSide}
                      style={{ textAlign: "center" }}
                    >
                      {t("10365")}
                    </Button>
                  </Grid>

                )
              }
            </Grid>
          ) : (
            <Grid item xs={3} />
          )
        }

        {/*Bottone per scaricare file*/}
        <Grid item xs={3} container alignItems="center">
          <Grid item xs={12} align="center">
            <p align="center" style={{ color: isError ? 'red' : 'black' }}>
              <u>{message}</u>
            </p>
          </Grid>
          {
            fileName !== "" ? (
              <Grid item xs={12} align="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={downloadFileConverted}
                  endIcon={<GetAppIcon />}
                >
                  {t("10366")}
                </Button>
              </Grid>
            ) : (
              null
            )
          }
        </Grid>
      </Grid>
    </Grid>

  )
}
