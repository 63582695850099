import React, { useEffect, useState, useContext, Fragment } from "react"
import { RootContext } from "../../RootContext"

// Core components
import Box from "@material-ui/core/Box"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import FileSaver from "file-saver"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { DropzoneArea } from "material-ui-dropzone"
import CardBody from "components/Card/CardBody.js"
import Button from "@material-ui/core/Button"


import { Stage, Layer, Line } from "react-konva"

// Pegaso components
import CompressUtils from "components/Tools/CompressUtils"
import EdgingDataUtils from "components/Utils/EdgingDataUtils"
import JobUtils from "components/Utils/JobUtils"
import JobFrame from "views/Job/JobFrameCatalogue/JobFrame"
import JobLoadedShape from "views/Job/JobLoadedShape/JobLoadedShape"
import OmaUtils from "components/Utils/OmaUtils"
import ShapeBar from "views/Shape/ShapeBar"
import ShapeModify from "views/Shape/ShapeModify"
import ShapeUtils from "components/Utils/ShapeUtils"
import Tracer from "views/Tracer/Tracer"
import cartaMMImage from "assets/img/CartaMM.png"

// Css
import { useShapeStyle, useShapeStyleGrids } from "styles/shape/shapeCss"
import DrillsManagement from "views/DrillsManagement/DrillsManagement"
import Drills from "components/Drills/Drills"
import ShapeEdit from "components/Shape/ShapeEdit"
import OpticalCenter from "components/Shape/OpticalCenter"
import { useDropzoneAreaStyle } from "styles/autocadConverter/autocadConverterCss"

export default function Shape(props) {
  // Compontents of table
  const {
    shape,
    edgingData,
    currentEdgingValues,
    frameBrands,
    frameSuppliers,
    scaleX,
    scaleY,
    editMode,
    isGlasant,
    isProgressive,
    jobId,
    creation,
    offset,
    handleDrillChange,
    handleUpdateEdgingData,
    handleNotesChangePrecal,
    jobSoftware,
    frameMode,
    rightValues,
    leftValues,
    setOpticalCenter,
    opticalCenter,
  } = props
  const classes = useShapeStyleGrids()
  const dropzoneClasses = useDropzoneAreaStyle()
  const {
    t,
    apiUrlPegasoTools,
    userDevice,
    getCalibration,
    getPrecalibrationActive,
    getLab,
    getEdgingEightType,
    getFrameManagementActive,
    getDiameterMinimumCalculationActive,
    access_token_pegaso_tools,
    refresh_token_pegaso_tools,
    setAccess_token_pegaso_tools,
  } = useContext(RootContext)
  const frameManagementActive = getFrameManagementActive()
  const diameterMinimumCalculationActive = getDiameterMinimumCalculationActive();
  const { maxInternalJobId } = getLab()
  const shapeClass = useShapeStyle.shape
  const [shapeJson, setShapeJson] = useState()
  const [shapeModifyJson, setShapeModifyJson] = useState()
  const [shapeExists, setShapeExists] = useState(editMode ? false : true)
  const canvasHeight = 350
  const canvasWidth = 800
  const [startRightLens, setStartRightLens] = useState(250)
  const originY = 150
  const [openDrillsManagement, setOpenDrillsManagement] = useState(false)
  const [openShapeModify, setOpenShapeModify] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)
  const [openTracer, setOpenTracer] = useState(false)
  const [openFrame, setOpenFrame] = useState(false)
  const [openLoadedShape, setOpenLoadedShape] = useState(false)
  const [calibration] = useState(getCalibration())
  const [currentShape, setCurrentShape] = useState("")
  const [currentEdgingData, setCurrentEdgingData] = useState(edgingData)
  const [wrongFormatFile, setWrongFormatFile] = useState(false)
  const [fileChargedData, setFileChargedData] = useState([])
  const [device] = useState(userDevice)
  const [cartaMMBackground] = React.useState(cartaMMImage)
  var sizeCartaMM = (3800 / calibration.dpiX) + "px";
  const [startLeftLens, setStartLeftLens] = useState(0)
  const precalibrationActive = getPrecalibrationActive()
  const decX = 2.5
  const decY = 4
  const tolProgressive = 2
  const tolMonofocal = 1
  const tolTouse = isProgressive ? tolProgressive : tolMonofocal
  const edgingEightType = getEdgingEightType()

  const origin = { x: startRightLens, y: originY }
  useEffect(() => {
    setCurrentShape(JobUtils.GetStandardShape({ shape: { standard: shape } }))
    setCurrentEdgingData(edgingData)
  }, [shape, edgingData, setOpticalCenter])
  useEffect(() => {
    if (setOpticalCenter) {
      setOpticalCenter({
        right: { start: { x: 0, y: 0 }, end: { x: 0, y: 0 }, diameter: 0, isRight: false, },
        left: { start: { x: 0, y: 0 }, end: { x: 0, y: 0 }, diameter: 0, isLeft: false, }
      })
    }
  }, [diameterMinimumCalculationActive, shape, setOpticalCenter])

  useEffect(() => {
    const shapeToDraw = ShapeUtils.extractShapeFromOMA(
      currentShape,
      currentEdgingData,
      scaleX,
      scaleY,
      offset,
      calibration
    )
    setShapeJson(shapeToDraw)
    var rightHbox = shapeToDraw.right.hbox ? shapeToDraw.right.hbox : 55
    var leftHbox = shapeToDraw.left.hbox ? shapeToDraw.left.hbox : 55
    setStartRightLens(
      canvasWidth / 2 - (shapeToDraw.dbl * 50 / 25 + rightHbox * 50 / 25)
    )
    setStartLeftLens(
      canvasWidth / 2 + (shapeToDraw.dbl * 50 / 25 + leftHbox * 50 / 25)
    )
    if (
      shapeToDraw.right.points.length > 1 ||
      shapeToDraw.left.points.length > 1
    ) {
      setShapeExists(true)
    } else {
      setShapeExists(false)
    }

  }, [
    calibration,
    currentShape,
    shape,
    edgingData,
    currentEdgingData,
    scaleX,
    scaleY,
    offset,
  ])

  function handleFileUpload() {
    var files = fileChargedData
    if (files.length > 0) {
      const reader = new FileReader()
      reader.readAsText(files[0])
      reader.onload = function () {
        const { result } = reader
        const fileShape = result
        var tracerDataConverted = OmaUtils.ConvertDataToOma(apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t, fileShape, 3)
        tracerDataConverted.then(function (result) {
          var dataToOma = result
          if (dataToOma.length === 2) {
            if (
              dataToOma[0].trim() !== "" ||
              dataToOma[1].trim() !== ""
            ) {
              setWrongFormatFile(false)
              const standardShape = dataToOma[0].trim()
              const edgingData = dataToOma[1]
              handleUpdateShape(fileShape, standardShape, edgingData, false)
              handleNotesChangePrecal(false)
            } else {
              setWrongFormatFile(true)
            }
          } else {
            setWrongFormatFile(true)
          }
        })
      }
      setOpenUpload(false)
    }
  }

  function handleUpdateShape(
    originalShape,
    standardShape,
    edgingData,
    hasDeviceUploader = false
  ) {
    var originalShapeCompressed = CompressUtils.zlibCompress(originalShape)
    var standardShapeCompressed = CompressUtils.zlibCompress(standardShape)

    setCurrentShape(
      JobUtils.GetStandardShape({
        shape: {
          original: originalShapeCompressed,
          standard: standardShapeCompressed,
        },
      })
    )
    setCurrentEdgingData(edgingData)
    handleUpdateEdgingData(
      standardShapeCompressed,
      edgingData,
      originalShapeCompressed,
      hasDeviceUploader
    )
  }

  function handleFrameUpload(
    originalShape,
    standardShape,
    edgingData,
    hasDeviceUploader = false
  ) {
    setCurrentShape(
      JobUtils.GetStandardShape({
        shape: {
          original: originalShape,
          standard: standardShape,
        },
      })
    )
    setCurrentEdgingData(edgingData)
    handleUpdateEdgingData(
      standardShape,
      edgingData,
      originalShape,
      hasDeviceUploader
    )
  }

  function handleTracerOpenClick() {
    setOpenTracer(true)
  }

  function handleFrameClick() {
    setOpenFrame(true)
  }

  function handleClickCloseFrame() {
    setOpenFrame(false)
  }

  function handleTracerCloseClick() {
    setOpenTracer(false)
  }

  function handleLoadedClick() {
    setOpenLoadedShape(true)
  }

  function handleLoadedCloseClick() {
    setOpenLoadedShape(false)
  }

  function handleExportClick() {
    var shapeDataConverted = JobUtils.convertshapeToExport(currentShape)
    var blob = new Blob([`${shapeDataConverted}\r\n${edgingData}`], {
      type: "text/plain;charset=utf-8",
    })
    if (jobId) {
      FileSaver.saveAs(blob, `job_${jobId}.txt`)
    } else {
      FileSaver.saveAs(blob, `job_new.txt`)
    }
  }

  function handleImportClick() {
    setOpenUpload(true)
  }


  function handleDeleteShapeClick() {
    let newLine = "\r\n"
    let cleanEdgingData = ""
    setCurrentShape("")
    // 2020/06/30 - Tolgo i vecchi drillingPoints (se ci sono)
    cleanEdgingData =
      edgingData.indexOf("DRILL") >= 0
        ? OmaUtils.RemoveOmaLabel(edgingData.split(newLine), "DRILL").join(
          newLine
        )
        : edgingData
    handleUpdateEdgingData("", cleanEdgingData, "", false)
    handleNotesChangePrecal(false)
    setOpenTracer(false)
  }

  function handleModifyShapeClick() {
    setOpenShapeModify(true)
  }

  function NoShape({ shapeExists }) {
    return (
      <div>
        {!shapeExists ? (
          <u>
            <strong>{t("10083").toUpperCase()}</strong>
          </u>
        ) : null}
      </div>
    )
  }

  function NoTracer({ deviceExists }) {
    return (
      <div>
        {!deviceExists ? (
          <u>
            <strong>{t("22").toUpperCase()}</strong>
          </u>
        ) : null}
      </div>
    )
  }


  /**
   * 
   * 
   * FUNZIONI GESTIONE FORI
   * 
   * 
   */

  //Apre la pagina di gestione dei fori
  function handleOpenDialogDrill() {
    setOpenDrillsManagement(true)
  }

  //Restituisce un booleano per l'abilitazione bottone fori
  function disableDrillButton() {
    if (isGlasant) {
      if (currentShape) {
        if (creation || editMode) {
          if (creation)
            return false
          else if (editMode && jobId < maxInternalJobId)
            return false
        }
      }
    }
    return true
  }

  //Sposta un foro
  const onDragEnd = e => {
    const id = parseFloat(e.target.name().split("_")[3]);
    var drillPoints = shapeJson.drillingPoints
    var newX = 0
    drillPoints = drillPoints.map((drill) => {
      if (drill.id === id) {
        if (e.target.name().indexOf("left") >= 0) {
          newX = parseFloat(((startLeftLens - e.target.x()) / scaleX).toFixed(2))
        }
        else if (e.target.name().indexOf("right") >= 0) {
          newX = parseFloat(((e.target.x() - origin.x) / scaleX).toFixed(2))
        }
        var newY = parseFloat(((origin.y - e.target.y()) / scaleY).toFixed(2));

        //origin.y - elem.y * scaleY
        drill = { ...drill, xStart: newX, yStart: newY, diam: drill.diam }
      }
      return drill
    })
    setShapeJson({ ...shapeJson, "drillingPoints": drillPoints })
  };

  //Aggiunge un foro cliccando
  const onClickStage = e => {
    if (openDrillsManagement) {
      const stage = e.target.getStage();
      const stageLocation = stage.getPointerPosition();
      var newX = 0
      if (stageLocation.x > canvasWidth / 2) {
        newX = parseFloat(((startLeftLens - stageLocation.x) / scaleX).toFixed(2))
      }
      else {
        newX = parseFloat(((stageLocation.x - origin.x) / scaleX).toFixed(2))
      }
      var newY = parseFloat(((origin.y - stageLocation.y) / scaleY).toFixed(2));
      handleAddDrill(newX, newY)
    }
  }

  //Aggiunge un foro
  function handleAddDrill(newX, newY) {
    //setRows([...rows, { id: rows.length + 1, x: 5.01, y: 5.01, diam: 1.00 }])
    var drillPoints = shapeJson.drillingPoints
    let maxId = 0;
    drillPoints.forEach(drill => {
      if (drill.id > maxId) {
        maxId = drill.id;
      }
    });
    if (maxId < 10) {
      setShapeJson({ ...shapeJson, "drillingPoints": [...drillPoints, { id: maxId + 1, xStart: newX, yStart: newY, diam: 1.00, isSlot: false }] })
    }
  }

  /**
     * 
     * 
     * FUNZIONI GESTIONE DROPZONE AREA INSERIMENTO FILE
     * 
     * 
     */

  //Handler change del file inserito
  function handleChangeFile(files) {
    setFileChargedData(files)
  }

  // Click Close Dialog
  function handleClickClose() {
    setOpenUpload(false)
    setFileChargedData([])
  }

  /**
     * 
     * 
     * FUNZIONI GESTIONE CENTRO OTTICO
     * 
     * 
     */
  function checkJsonAllFields(obj) {
    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!checkJsonAllFields(obj[key])) {
          return false;
        }
      } else {
        if (obj[key] === undefined || obj[key] === 0) {
          return false;
        }
      }
    }
    return true;
  }

  function checkCalculateDiameterField() {
    const shapeDataRight = shapeJson.right;
    const shapeDataLeft = shapeJson.left;
    var resultRight = false;
    var resultLeft = false;
    if (shapeJson.dbl !== "0") {
      if (shapeDataRight !== undefined) {
        if (!isNaN(shapeDataRight.hbox)) {
          if (!isNaN(shapeDataRight.vbox)) {
            if (!isNaN(shapeDataRight.ocht)) {
              if (!isNaN(shapeDataRight.ipd)) {
                resultRight = true;
              }
            }
          }
        }
      }
      if (shapeDataLeft !== undefined) {
        if (!isNaN(shapeDataLeft.hbox)) {
          if (!isNaN(shapeDataLeft.vbox)) {
            if (!isNaN(shapeDataLeft.ocht)) {
              if (!isNaN(shapeDataLeft.ipd)) {
                resultLeft = true;
              }
            }
          }
        }
      }
    }
    else {
      alert("inserire dati ponte")
    }
    return resultRight || resultLeft;
  }

  async function handleCalculateDiameterClick() {
    if (checkCalculateDiameterField()) {
      if (diameterMinimumCalculationActive) {
        var edgingDataForOpticalCenter = currentEdgingData
        if (isProgressive === true) {
          edgingDataForOpticalCenter += `SGOCIN=${decX};${decX}\r\nSGOCUP=${decY};${decY}`
        }
        if (edgingEightType === "EDGE") {
          var ochtValue = await EdgingDataUtils.convertSeghtToOcht(rightValues, leftValues, shape, edgingData, currentEdgingValues, apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t)
        }

        edgingDataForOpticalCenter += `SGOCIN=${decX};${decX}\r\nSGOCUP=${decY};${decY}\r\n${ochtValue}`
        var calculateOpticalCenter = OmaUtils.CalculateOpticalCenter(apiUrlPegasoTools, access_token_pegaso_tools, refresh_token_pegaso_tools, setAccess_token_pegaso_tools, t, JobUtils.convertshapeToExport(currentShape), edgingDataForOpticalCenter)
        calculateOpticalCenter.then(function (result) {
          var rightEndWithTol = result.right.end.x > 0 ? result.right.end.x + tolTouse : result.right.end.x - tolTouse
          var leftEndWithTol = result.left.end.x > 0 ? result.left.end.x + tolTouse : result.left.end.x - tolTouse
          var isRight = checkJsonAllFields(result.right)
          var isLeft = checkJsonAllFields(result.left)
          var rightDiameter = isRight ? ShapeUtils.calculateDistance(result.right.start.x, result.right.start.y, rightEndWithTol, result.right.end.y) : 0
          var leftDiameter = isLeft ? ShapeUtils.calculateDistance(result.left.start.x, result.left.start.y, leftEndWithTol, result.left.end.y) : 0
          const updatedData = {
            ...result,
            right: {
              ...result.right,
              diameter: Math.floor(2 * rightDiameter),
              isRight: isRight
            },
            left: {
              ...result.left,
              diameter: Math.floor(2 * leftDiameter),
              isLeft: isLeft
            }
          };
          setOpticalCenter(updatedData)
        })

      }
    }
  }

  return (
    <div >
      {/* POPUP - UPLOAD */}
      {(editMode && jobSoftware !== "minigui") || creation ? (
        <Fragment>
          <Dialog
            open={openUpload}
            fullWidth
            maxWidth="sm"
            onClose={handleClickClose}
          >
            <CardBody>
              <h4><strong>Upload File</strong></h4>
              <DropzoneArea
                acceptedFiles={['.oma', '.dat', '.txt']}
                dropzoneClass={dropzoneClasses.myDropZone}
                dropzoneParagraphClass={dropzoneClasses.dropZoneText}
                dropzoneText={t("10386")}
                filesLimit={1}
                onChange={handleChangeFile.bind(this)}
                maxFileSize={100000}
                useChipsForPreview={true}
              />
              <Box
                m={1}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ border: 'none' }}
                  onClick={handleClickClose}
                >
                  {t("10407")}
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ border: 'none', align: 'right' }}
                  onClick={handleFileUpload}
                  disabled={!fileChargedData.length > 0}
                >
                  {t("10408")}
                </Button>
              </Box>
            </CardBody>
          </Dialog>
        </Fragment>

      ) : null}

      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        flexDirection="column"
      >
        < Box p={1}>
          {/* BUTTONS */}
          <ShapeBar
            currentShape={currentShape}
            device={device}
            disableDrillButton={disableDrillButton}
            frameManagementActive={frameManagementActive}
            handleFrameClick={handleFrameClick}
            handleTracerClick={handleTracerOpenClick}
            handleImportClick={handleImportClick}
            handleExportClick={handleExportClick}
            handleDeleteShapeClick={handleDeleteShapeClick}
            handleModifyShapeClick={handleModifyShapeClick}
            handleOpenDialogDrill={handleOpenDialogDrill}
            handleLoadedClick={handleLoadedClick}
            handleCalculateDiameterClick={handleCalculateDiameterClick}
            openDrillsManagement={openDrillsManagement}
            openShapeModify={openShapeModify}
            openTracer={openTracer}
            showBar={(creation) || (!creation && jobId < maxInternalJobId)}
            showImport={(editMode && jobSoftware !== "minigui") || (creation && jobId > maxInternalJobId) || frameMode}
          />
        </Box>


        {/* DISEGNO */}
        <Box p={1} alignSelf="center">
          {!openTracer && currentShape ? (
            <div>
              <Stage
                width={canvasWidth}
                height={canvasHeight}
                style={(!disableDrillButton() && openDrillsManagement) || openShapeModify ? { backgroundImage: "url(" + cartaMMBackground + ")", backgroundSize: sizeCartaMM } : null}
                onClick={onClickStage}
              >
                {/* DISEGNO FORMA (BLU) */}
                {shapeExists && !openDrillsManagement ? (
                  <Layer>
                    {/* 1 - LENTE DESTRA (origine = centro lente) */}
                    <Line
                      closed
                      fill={!openShapeModify ? shapeClass.fill : null}
                      points={shapeJson ? shapeJson.right.points : []}
                      stroke={!openShapeModify ? shapeClass.stroke : 'blue'}
                      strokeWidth={shapeClass.strokeWidth}
                      tension={0}
                      x={origin.x}
                      y={origin.y}
                    />
                    {/* FORI (Lente Destra) */}
                    <Drills
                      onDragEnd={onDragEnd}
                      openDrillsManagement={openDrillsManagement}
                      origin={origin}
                      scaleX={scaleX}
                      scaleY={scaleY}
                      shapeJson={shapeJson}
                      shapeClass={shapeClass}
                      startLens={origin.x}
                      side={"right"}
                      stroke={!openShapeModify ? shapeClass.stroke : 'blue'}
                    />
                    { }
                    {/* 2 - LENTE SINISTRA (origine = centro lente) */}
                    <Line
                      closed
                      fill={!openShapeModify ? shapeClass.fill : null}
                      points={shapeJson ? shapeJson.left.points : []}
                      tension={0}
                      stroke={!openShapeModify ? shapeClass.stroke : 'blue'}
                      strokeWidth={shapeClass.strokeWidth}
                      x={startLeftLens}
                      y={origin.y}
                    />
                    {/* FORI (Lente Sinistra) */}
                    <Drills
                      onDragEnd={onDragEnd}
                      openDrillsManagement={openDrillsManagement}
                      origin={origin}
                      scaleX={scaleX}
                      scaleY={scaleY}
                      shapeJson={shapeJson}
                      shapeClass={shapeClass}
                      startLens={startLeftLens}
                      side={"left"}
                      stroke={!openShapeModify ? shapeClass.stroke : 'blue'}
                    />
                  </Layer>
                ) : null}

                {/* DISEGNO FORI FORMA (ROSSO) */}
                {
                  shapeExists && openDrillsManagement ? (
                    <ShapeEdit
                      isDraggable={true}
                      origin={origin}
                      openDialog={openDrillsManagement}
                      onDragEnd={onDragEnd}
                      scaleX={scaleX}
                      scaleY={scaleY}
                      shapeClass={shapeClass}
                      shapeJson={shapeJson}
                      startLeftLens={startLeftLens}
                      stroke={'red'}
                    />
                  ) : null
                }

                {/* DISEGNO MODIFICA FORMA (ROSSO) */}
                {
                  shapeExists && openShapeModify && shapeModifyJson ? (
                    <ShapeEdit
                      openDialog={openShapeModify}
                      origin={origin}
                      scaleX={scaleX}
                      scaleY={scaleY}
                      shapeJson={shapeModifyJson}
                      shapeClass={shapeClass}
                      startLeftLens={startLeftLens}
                      stroke={'red'}
                    />
                  ) : null
                }

                {/* DISEGNO CENTRO OTTICO */}
                {
                  diameterMinimumCalculationActive ? (
                    <OpticalCenter
                      calibration={calibration}
                      decX={decX}
                      decY={decY}
                      isProgressive={isProgressive}
                      opticalCenter={opticalCenter}
                      origin={origin}
                      rightValues={rightValues}
                      leftValues={leftValues}
                      startLeftLens={startLeftLens}
                      t={t}
                      tolTouse={tolTouse}

                    />
                  ) : (null)
                }
              </Stage>

            </div>

          ) : null}
        </Box>


        {/* ERRORE - UPLOAD */}
        <Box p={1} alignSelf="flex-end">
          {(editMode && jobSoftware !== "minigui") ? (
            <Typography align="right" color="error">
              {wrongFormatFile ? t("10151") : ""}
            </Typography>
          ) : null}
        </Box>
      </Box>

      {/* POPUP - TRACER */}
      {
        openTracer ? (
          device ? (
            <Tracer
              handleUpdateShape={handleUpdateShape}
              handleClose={handleTracerCloseClick}
              handleNotesChangePrecal={handleNotesChangePrecal}
            />
          ) : (
            <Grid container spacing={2}>
              <Grid align="center" item xs={12}>
                <NoTracer deviceExists={device} />
              </Grid>
            </Grid>
          )
        ) : (
          <Grid container spacing={2}>
            <Grid align="center" item xs={12}>
              <NoShape shapeExists={shapeExists} />
            </Grid>
          </Grid>
        )
      }

      {/*GESTIONE FORI*/}
      {
        openDrillsManagement ? (
          <div className={classes.shapeDrillGrid}>
            <DrillsManagement
              {...props}
              classes={classes}
              currentEdgingData={currentEdgingData}
              currentShape={currentShape}
              handleAddDrill={handleAddDrill}
              handleDrillChange={handleDrillChange}
              openCard={openDrillsManagement}
              scaleX={scaleX}
              scaleY={scaleY}
              setOpenDrillsManagement={setOpenDrillsManagement}
              setShapeJson={setShapeJson}
              shapeJson={shapeJson}
            />
          </div>) : (null)
      }

      {/*MODIFICA FORMA*/}
      {
        openShapeModify ? (
          <div className={classes.shapeModifyGrid}>
            <ShapeModify
              {...props}
              calibration={calibration}
              edgingData={edgingData}
              handleUpdateShape={handleUpdateShape}
              scaleX={scaleX}
              scaleY={scaleY}
              setWrongFormatFile={setWrongFormatFile}
              shapeJson={shapeJson}
              shapeModifyJson={shapeModifyJson}
              setOpenShapeModify={setOpenShapeModify}
              setShapeModifyJson={setShapeModifyJson}
            />
          </div>) : (null)
      }


      {/*INSERIMENTO MONTATURA DA CATALOGO*/}
      {
        frameManagementActive && openFrame ? (
          <div>
            <Dialog
              onClose={handleClickCloseFrame}
              aria-labelledby="customized-dialog-title"
              open={openFrame}
              maxWidth="xl"
            >
              <DialogContent>
                <JobFrame
                  frameBrands={frameBrands}
                  frameSuppliers={frameSuppliers}
                  handleFrameUpload={handleFrameUpload}
                  setOpenFrameList={setOpenFrame}
                  onHandleClose={handleClickCloseFrame}
                />
              </DialogContent>
            </Dialog>
          </div>
        ) : (
          null
        )
      }

      {/*INSERIMENTO MONTATURA DA CATALOGO*/}
      {
        precalibrationActive && openLoadedShape ? (
          <div>
            <Dialog
              onClose={handleClickCloseFrame}
              aria-labelledby="customized-dialog-title"
              open={openLoadedShape}
              maxWidth="xl"
            >
              <DialogContent>
                <JobLoadedShape
                  handleFrameUpload={handleFrameUpload}
                  onHandleClose={handleLoadedCloseClick}
                  setOpenLoadedShape={setOpenLoadedShape}
                  handleNotesChangePrecal={handleNotesChangePrecal}
                />
              </DialogContent>
            </Dialog>
          </div>
        ) : (
          null
        )
      }


    </div >
  )
}
