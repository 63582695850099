/*************************************************************************
                  VALORI PER SELECT
 *************************************************************************/

export default class JobNewUtils {
  // Crea lo stato iniziale della schermata
  static createDefaultState(
    jobData,
    companyId,
    companyName,
    labId,
    labName,
    opticianId,
    opticianName
  ) {
    const left = {
      side: "L",
      ltyp: "",
      dia: "",
      sph: "",
      ipd: "",
      cyl: "",
      ocht: "",
      ax: "",
      hbox: "",
      add: "",
      vbox: "",
      lmattype: "",
      _lcoat: "",
      thkp: "",
      cthick: "",
      fcrv: "",
      frnt: "",
      ztilt: "",
      _seght: "",
      panto: "",
      bvd: "",
      prvm: "",
      prva: "",
    }

    const right = {
      side: "R",
      ltyp: "",
      dia: "",
      sph: "",
      ipd: "",
      cyl: "",
      ocht: "",
      ax: "",
      hbox: "",
      add: "",
      vbox: "",
      lmattype: "",
      _lcoat: "",
      thkp: "",
      cthick: "",
      fcrv: "",
      frnt: "",
      ztilt: "",
      _seght: "",
      panto: "",
      bvd: "",
      prvm: "",
      prva: "",
    }

    const edging = {
      etyp: "",
      bevp: "",
      bevm: "",
      polish: "",
      ftyp: "",
      dbl: "",
      offset: "",
      drillingPoints: [],
      drillePoints: [],
      gwidth: "",
      gdepth: "",
      fpinb: "",
      pinb: "",
      do: "",
    }

    const head = {
      company: {
        id: companyId,
        name: companyName
      },
      lab: {
        id: labId,
        name: labName
      },
      optician: {
        id: opticianId,
        name: opticianName
      }
    }

    const status = {
      rightValues: right,
      leftValues: left,
      edgingValues: edging,
      notesValues: {
        dx: true,
        labNote: "",
        lensOrderLabReference: "",
        lensOrderReference: "",
        lensProduct: "",
        coating: "",
        isPersonalized: false,
        isProgressive: false,
        opticianCustomer: "",
        opticianNote: "",
        precal: false,
        sx: true
      },
      headValues: head
    }

    return status
  }
}
